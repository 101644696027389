.aui-root {
  .notification-option {
    justify-content: space-between;
    margin: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--tertiary-light);

    &:first-of-type {
      margin-top: 6rem;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &__text {
      margin: 0;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
