.modal {
  &.aui-overlay {
    z-index: -10;
    opacity: 0;
    top: -2rem;
    transition: 0.2s ease-in-out all;
    transition-delay: 0.1s;
    visibility: hidden;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &--visible {
    &.aui-overlay {
      z-index: 4490;
      opacity: 1;
      top: 0;
      visibility: visible;

      @media (max-width: 1024px) {
        display: flex;
      }
    }
  }
}
