.aui-root {
  .fullscreen-modal-wrapper {
    height: 100vh;
    width: 100%;
  }

  .fullscreen-modal {
    display: flex;
    flex-direction: column;
    z-index: 1001;

    &__top {
      height: 7.2rem;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__buttons {
        display: flex;
        flex-direction: row;
        margin-right: 2rem;

        .btn {
          padding: 1rem 1.6rem;

          &:last-of-type {
            margin-left: 1rem;
          }
        }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: calc(100% - 7.2rem);

      &__btn-wrapper {
        width: 10rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1.2rem;
        position: relative;

        @media (max-width: 1024px) {
          display: none;
        }

        &:hover {
          .fullscreen-modal__slider-button {
            background: var(--tertiary-contrast);
            border-color: var(--primary);
          }
          &:before {
            opacity: 1;
          }
        }

        &[aria-disabled="true"]:hover {
          &:before {
            pointer-events: none;
            opacity: 0;
          }
        }

        &:before {
          content: "";
          background: rgba(255, 255, 255, 0.1);
          position: absolute;
          top: 1.2rem;
          bottom: 1.2rem;
          right: 1.2rem;
          left: 1.2rem;
          border-radius: 12px;
          opacity: 0;
          transition: 0.15s ease-in-out opacity;
          cursor: pointer;
        }
      }

      &__slider {
        width: 100%;
        overflow: hidden;
        height: calc(100vh - 9.8rem);
        border-radius: 12px;

        @media (max-width: 1024px) {
          padding: 0 1.2rem;
        }

        &--full-width {
          padding: 0 1.2rem;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;

          img {
            max-width: initial;
            height: 100%;
            object-fit: fill;
            border-radius: 12px;
          }
        }
      }
    }

    &__slider-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--white);
      width: 4.4rem;
      height: 4.4rem;
      border: 1px solid transparent;
      transition: 0.15s ease-in-out all;

      svg {
        height: 2.2rem;

        path {
          fill: var(--primary);
        }
      }

      &--left {
        left: 2rem;
      }

      &--right {
        right: 2rem;
      }
    }
  }
}
