.pagetable-head-wrapper {
  display: flex;
  padding: 1.2rem 2.4rem;
  align-items: center;
  position: relative;
  min-height: 5.2rem;
  border-bottom: 1px solid var(--light);
  background-color: var(--white);
  gap: 1.2rem;

  @media (max-width: 768px) {
    padding: 0.6rem 2.4rem;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6rem 0;
  }

  .btn.light {
    border: 1px solid var(--light);
    color: var(--defaultTextColor);
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem 1.6rem;

    &.filter-active::after {
      content: "";
      width: 0.6rem;
      height: 0.6rem;
      background-color: var(--warning);
      display: block;
      border-radius: 50px;
    }
  }

  &--no-btns {
    border-bottom: 1px solid var(--light);
    margin: 0.1rem -2.4rem 0;
    padding: 0 2.4rem 1.2rem;
  }

  .pagetable-head-text-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }

  .pagetable-head-title {
    color: var(--warning-contrast);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.8rem;
  }

  .pagetable-head-description {
    height: 2.8rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: 16px;
    background: var(--tertiary-contrast);
    color: var(--primary-contrast);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 640px) {
      margin: 1rem 0;
    }

    .btn {
      margin-right: 1.5rem;

      &:last-of-type {
        margin-right: 0;
      }

      &.btn-grid {
        padding: 0.65rem 1.6rem;
        .aui-switch {
          height: 2.1rem;
          margin-left: 0.5rem;

          .aui-icon {
            height: 1.35em;
            width: 1.35em;
            top: 66%;
            left: 26%;
          }

          &.active {
            .aui-icon:last-child {
              left: 74%;
            }
          }
        }
      }
    }
  }
}

.columns-modal-content {
  padding: 1.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .header {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--light);

    @media (max-width: 450px) {
      top: 0;
      position: fixed;
      background: var(--white);
      z-index: 1;
      width: 100%;
      padding: 1.5rem 2.5rem 1rem;
      margin-left: -2.5rem;
      border-radius: 8px 8px 0 0;
    }
  }

  h4 {
    margin: 0;
    font-size: large;
    font-weight: 700;
    line-height: 28px;
    color: var(--dark);
  }

  p {
    margin: 0;
  }

  .columnchoose-wrapper {
    background-color: var(--light-contrast);
    border-radius: var(--radius);
    border: 1px solid var(--light);
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    gap: 6px;
    max-height: 400px;
    overflow: auto;

    @media (max-width: 450px) {
      max-height: 100%;
      margin: 9rem 0 6.5rem;
    }

    .column-checkbox {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      background: var(--white);
      border: 1px solid var(--light);
      padding: 1.2rem 2rem;
      border-radius: var(--radius);
      margin-bottom: 0;
      transition: border-color 0.2s ease-out;

      .aui-icon {
        width: 1.8rem;
        height: 1.8rem;
      }

      &:hover {
        border-color: var(--primary);
      }

      &:hover,
      &:focus-within {
        .checkmark {
          border-color: var(--primary);

          &.checked {
            background-color: var(--primary);
            color: var(--white);
            border-color: var(--primary);
          }
        }
      }
    }
  }

  .btn-link.reset {
    font-size: 1.4rem;
    font-weight: 600;
    align-self: flex-end;

    @media (max-width: 768px) {
      position: fixed;
      background: var(--white);
      bottom: 0;
      left: 0;
      right: 0;
      text-align: right;
      padding: 2rem;
      display: flex;
      justify-content: end;
      border-top: 1px solid var(--light);
    }
  }
}

.aui-root div.popup-menu.colums-selector-popup.fullscreen {
  overflow: hidden;
  .columns-modal-content {
    overflow: hidden;
  }

  .columnchoose-wrapper {
    max-height: initial;
  }
}
