.pagewrapper-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding-top: 1.2rem;

  @media (max-width: 768px) {
    gap: 1.2rem;
  }

  &--table {
    @media (min-width: 1200px) {
      overflow: hidden;
      height: 100%;
    }
  }

  &--big {
    height: 100%;
  }

  &--more-padding {
    padding-bottom: 7.2rem;

    @media (max-width: 1640px) {
      padding-bottom: 4.2rem;
    }

    @media (max-width: 900px) {
      padding-bottom: 1.2rem;
    }
  }
}
