.aui-root {
  .slider-circle-nav {
    background: var(--tertiary-contrast);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.15s ease background;
    transform: translateY(-50%);
    z-index: 10;

    &:hover {
      background: var(--primary);

      svg path {
        stroke: var(--tertiary-contrast);
      }
    }

    svg path {
      transition: 0.15s ease stroke;
      stroke: var(--primary);
    }

    &--prev {
      transform: rotate(-180deg) translateY(50%);
    }

    &--color-primary {
      background: var(--primary);

      svg path {
        stroke: var(--tertiary-contrast);
      }

      &:hover {
        background: var(--tertiary-contrast);

        svg path {
          stroke: var(--primary);
        }
      }
    }
  }
}
