.organisation {
  background: var(--light-contrast);
  flex: 1;
  padding: 11rem 31.5rem;
  overflow: scroll;
  height: 100vh;

  &--dark {
    background: transparent;
    padding: 11rem 17.5rem;

    .organisation {
      &__back {
        display: flex;
        flex-direction: row;

        svg {
          transform: rotate(-180deg);
        }

        p {
          margin: 0 0 0 1rem;
        }
      }

      &-box-wrapper {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-box--create {
        border-color: var(--primary);
      }
    }

    .heading {
      h3,
      h6 {
        color: var(--white);
      }
    }
  }

  @media (max-width: 1840px) {
    padding: 8rem 20rem;
  }

  @media (max-width: 1640px) {
    padding: 8rem 10rem;
  }

  @media (max-width: 1240px) {
    padding: 8rem 2rem;
  }

  @media (max-width: 1024px) {
    padding: 2rem;
  }

  @media (max-width: 640px) {
    // overflow: visible;
    height: auto;
    overflow: auto;
  }

  .organisation-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -1.2rem;
    padding-bottom: 2rem;

    @media (max-width: 1024px) {
      margin: -0.8rem;
    }

    @media (max-width: 640px) {
      padding-bottom: 2rem;
    }

    &::-webkit-scrollbar {
      background: var(--light-contrast);
      width: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--radius);
      background: var(--light);
    }

    &::-webkit-scrollbar-track-piece {
      background: var(--light-contrast);
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem;

    .heading {
      margin-bottom: 0;

      h6 {
        margin-bottom: 0;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  &__help {
    display: flex;
    align-items: center;
    margin-right: 5rem;
    cursor: pointer;

    @media (max-width: 768px) {
      margin: 0 2rem;
    }

    svg {
      vertical-align: 0.4em;
    }

    p {
      margin: 0 0 0 0.8rem;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  .sidebar__user__img {
    border: 2px solid transparent;
    transition: 0.15s ease-in-out border;
    cursor: pointer;

    &:hover {
      border-color: var(--primary);
    }

    &--default {
      border: 3px solid transparent;
      transition: 0.15s ease-in-out all;
      cursor: pointer;

      &:hover {
        border: 3px solid var(--primary);
        background: var(--primary-contrast);
      }
    }
  }

  &__logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
    cursor: pointer;

    &:hover {
      svg path {
        fill: var(--primary-contrast);
      }

      p {
        color: var(--primary-contrast);
        text-decoration: underline;
      }
    }

    svg path {
      fill: var(--primary);
      transition: 0.15s ease-in-out fill;
    }

    &__text {
      padding-left: 1rem;
      transition: 0.15s ease-in-out color;
      white-space: nowrap;
    }
  }
}
