.pagetitle-wrapper {
  border-bottom: 1px solid var(--light);
  padding: 0rem 0rem 1rem 0rem;

  @media (max-width: 768px) {
    flex-direction: column;
    border-bottom: none;
    padding: 0;
  }
}

.pagetitle-title {
  color: var(--dark);
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.8rem;
}

.pagetitle-subtitle {
  color: var(--dark-contrast);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.pagetitle-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-horizontal-tabs-wrapper {
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
}

.pagetitle-buttons {
  gap: 1rem;

  .btn {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}
