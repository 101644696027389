.aui-root {
  .slider-preview {
    position: relative;
    height: 36.9rem;
    min-height: 36.9rem;
    max-width: 59rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--dark-contrast);
    border: 1px solid var(--light);
    overflow: hidden;

    &:hover {
      .slider-preview__btn__text,
      .slider-preview__fullscreen {
        opacity: 1;
        visibility: visible;
      }
    }

    @media (min-width: 2000px) {
      max-width: initial;
    }

    @media (max-width: 1640px) {
      height: 30rem;
      min-height: 30rem;
    }

    @media (max-width: 1200px) {
      height: 39rem;
      min-height: 39rem;
      max-width: 100%;
    }
    @media (max-width: 640px) {
      min-height: 30rem;
    }

    &--file {
      height: 100%;
      width: 100%;
    }

    &__img {
      height: 100%;
      border-radius: 0;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .swiper,
    .lazy-load-image-background {
      width: 100%;

      img {
        margin: 0 auto;
        display: flex;
        height: 38rem;

        @media (max-width: 1640px) {
          height: 31rem;
          width: 100%;
        }

        @media (max-width: 1200px) {
          height: 40rem;
        }

        @media (max-width: 640px) {
          max-width: initial;
          object-fit: fill;
        }
      }
    }

    &__img-amount {
      position: absolute;
      z-index: 20;
      left: 2rem;
      top: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 42px;
      background-color: var(--white);
      margin: 0;
      border: 1px solid var(--primary);
      padding: 0.4rem 0.8rem;
      color: var(--primary);
      transition: 0.15s ease-in-out all;
    }

    &__fullscreen {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: var(--white);
      padding: 1rem 1.6rem;
      margin: 0;
      position: absolute;
      top: calc(50% - 2rem);
      border: 1px solid transparent;
      transition: 0.15s ease-in-out all;
      z-index: 20;
      opacity: 0;
      visibility: hidden;

      span {
        display: inline-block;
        margin-left: 1rem;
        transition: 0.15s ease-in-out color;
      }

      svg path {
        transition: 0.15s ease-in-out fill;
      }

      &:hover {
        border-color: var(--primary);
        background: var(--tertiary-contrast);

        svg path {
          fill: var(--primary);
        }

        span {
          color: var(--primary);
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--white);
      padding: 0;
      margin: 0;
      width: 4.4rem;
      height: 4.4rem;
      position: absolute;
      top: calc(50% - 2rem);
      border: 1px solid transparent;
      transition: 0.15s ease-in-out all;
      z-index: 20;

      &:hover {
        background: var(--tertiary-contrast);
        border-color: var(--primary);
      }

      &__text {
        color: var(--white);
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease-in-out all;

        @media (min-width: 1400px) and (max-width: 1500px) {
          display: none;
        }

        @media (min-width: 1200px) and (max-width: 1300px) {
          display: none;
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }

      svg {
        height: 2.2rem;

        path {
          fill: var(--primary);
        }
      }

      &--left {
        left: 2rem;

        &--text {
          left: 6rem;
        }
      }

      &--right {
        right: 2rem;

        &--text {
          right: 6rem;
        }
      }
    }
  }
}
