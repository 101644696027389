.accept-privacy-modal {
  .accept-privacy-container {
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--radius);

    form .btn {
      margin-bottom: 0;
      margin-top: 2rem;
    }

    .message-container {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }
}
