.aui-root {
  .profile-policy {
    &__title {
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-weight: 600;
      color: var(--dark);
      margin: 0 0 0.4rem 0;
    }

    &__checkboxes {
      margin-top: 2rem;
    }

    &__text {
      font-size: 1.4rem;
      line-height: 2rem;
      color: var(--input-text);
      margin: 0;

      a {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
    &__delete-wrapper {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__delete-modal {
      &__text {
        margin: 0 0 1rem;
        font-size: 1.4rem;
        line-height: 2rem;

        &--bold {
          margin-top: 1rem;
          font-weight: 600;
        }

        &:first-of-type {
          margin-top: -1.4rem;
        }
      }

      a {
      }
    }
  }
}
