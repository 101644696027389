.info-icon {
  background-color: white;
  border: none;
  outline: none;
}

.emission-card-info-popup {
  p {
    margin: 0;
  }
}
