.aui-root {
  .pagination {
    display: flex;
    position: relative;
    gap: 0;

    @media (max-width: 768px) {
      .text {
        display: none;
      }
    }

    @media (max-width: 550px) {
      margin-left: 0;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      padding: 0 1.2rem;
      border: none;
      background-color: var(--white);
      height: 4rem;
      min-width: 4rem;
      color: var(--gray-700, #404968);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem;
      border-right: 1px solid var(--input-border-color);
      border-bottom: 1px solid var(--input-border-color);
      border-top: 1px solid var(--input-border-color);
      transition: 0.15s ease-in-out all;
      cursor: pointer;
      position: relative;

      &:first-of-type {
        border-radius: 8px 0 0 8px;
        border-left: 1px solid var(--input-border-color);

        &:before {
          display: none;
        }
      }

      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }

      &:before {
        content: "";
        position: absolute;
        left: -0.1rem;
        top: -0.1rem;
        bottom: -0.1rem;
        width: 0.1rem;
        background: var(--primary);
        opacity: 0;
        transition: 0.15s ease-in-out all;
      }

      &:hover {
        border-color: var(--primary);
        color: var(--primary);

        &:before {
          opacity: 1;
        }
      }

      &.active {
        border-color: var(--primary);
        color: var(--primary);
        font-weight: 700;

        &:before {
          opacity: 1;
        }

        &:first-of-type {
          border-left: 1px solid var(--primary);
        }
      }

      &:disabled {
        pointer-events: none;
        color: var(--tertiary-darker);

        svg path {
          stroke: var(--tertiary-darker);
        }
      }
    }

    &__arrow {
      svg {
        width: 2rem;
        height: 2rem;

        path {
          stroke-width: 0.03rem;
          stroke: var(--gray-700, #404968);
        }
      }
    }
  }
}
