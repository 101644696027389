.aui-root .dashboard {
  @media (max-width: 768px) {
    padding: 1.3rem 0 0.8rem 0;
  }
  padding: 1.3rem 7.2rem;

  header {
    margin-bottom: 5rem;

    h1 {
      font-size: 30px;
      font-weight: 600;
      color: var(--dark);
      margin: 0;
    }

    .current-organisation {
      margin-top: 1rem;
    }

    a {
      font-weight: 600;
      text-decoration: none;
    }
  }

  .section-header {
    gap: 1rem;
    margin-bottom: 2rem;

    .aui-icon {
      font-size: 1.6rem;
    }

    h4 {
      color: var(--dark-contrast);
      font-weight: 700;
      margin: 0;
    }

    &__statistics {
      display: flex;
      justify-content: start;
      > :last-child {
        margin-left: auto;
        align-content: baseline;
        display: flex;
        color: var(--gray-400, #7d89b0);
        .selected {
          color: var(--primary);
        }
        gap: 1rem;
        span {
          text-decoration: none;
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
          .selected {
            color: var(--primary);
          }
        }
      }
    }
  }

  .card-container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
    margin-bottom: 5rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 3.6rem;
      gap: 1.2rem;
    }

    .add-new-link {
      margin-bottom: 0;
    }
  }

  .card {
    width: 100%;
    background: var(--white);
    border-radius: 16px;
    padding: 2rem 2.5rem;
    border: var(--input-border-width) solid var(--gray-200, #dcdfea);

    h4 {
      color: var(--gray-600);
      font-weight: 700;
      margin: 0;
    }

    .amount-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0;

      .amount {
        font-weight: 700;
        font-size: 36px;
        color: var(--primary);
      }

      .unit {
        font-weight: 500;
        font-size: 20px;
        color: var(--gray-400, #7d89b0);
      }

      &.small {
        gap: 0.5rem;
        margin: 0.5rem 0;

        .amount {
          font-size: 14px;
        }

        .unit {
          font-size: 14px;
        }
      }
    }
  }

  .emissions-card {
    .header {
      justify-content: space-between;
    }

    p {
      margin: 2.5rem 0 0;
    }

    .info-icon {
      cursor: pointer;
      path {
        fill: var(--primary);
      }
    }
  }

  .statistics-card {
    .amount-container {
      margin: 2.5rem 0;
    }

    .location-icon,
    .objects-icon,
    .projects-icon {
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      path {
        fill: var(--primary);
      }
    }
  }

  .course-box {
    display: block;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    margin-bottom: 7rem;
    background: linear-gradient(to right, var(--primary), var(--primary-light));
    border-radius: 16px;
    padding: 2rem 2.5rem;
    transition: 0.2s ease-in-out all;
    top: 0;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      margin-bottom: 3.6rem;
    }

    &:hover {
      filter: brightness(1.1);

      .course-box__content {
        top: -0.5rem;
      }
    }
    &__content {
      position: relative;
      transition: 0.2s ease-in-out top;
      top: 0;
      z-index: 2;
    }
    h3 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0;
      color: var(--white);
    }

    h2 {
      font-weight: 700;
      font-size: 36px;
      margin-top: 8px;
      color: var(--white);
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    &__contact {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      margin-top: 8px;
      color: var(--white);
      gap: 1rem;
      &-text {
        margin: 0;
      }
    }
    &__star1 {
      position: absolute;
      top: 49px;
      right: 150px;
      z-index: 1;
      @media (max-width: 450px) {
        top: 49px;
        right: 50px;
      }
    }
    &__star2 {
      position: absolute;
      top: 10px;
      right: 300px;
      z-index: 1;
      @media (max-width: 450px) {
        top: 10px;
        right: 200px;
      }
    }
    &__star3 {
      position: absolute;
      top: 0px;
      right: 80px;
      z-index: 1;
      @media (max-width: 450px) {
        top: 0px;
        right: 0px;
      }
    }
  }
}
