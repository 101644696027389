.aui-root {
  .heading {
    margin-bottom: 4rem;
    justify-content: space-between;

    &__text {
    }

    h3 {
      color: var(--dark);
      font-weight: 600;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
    }
    h6 {
      color: var(--input-text);
      font-weight: 400;
    }

    &__small {
      border-bottom: 1px solid var(--light);
      padding-bottom: 1rem;
      margin-bottom: 2rem;

      h3 {
        font-size: 1.8rem;
        line-height: 2.8rem;
        margin-bottom: 0.4rem;
      }

      h6 {
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 0;
      }
    }

    &__tooltip {
      margin: 0 0 0 1rem;
      border-radius: 16px;
      background: var(--tertiary-contrast);
      color: var(--primary-dark);
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 500;
      padding: 0.2rem 0.8rem;
    }
  }

  &__small {
    border-bottom: 1px solid var(--light);
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    h3 {
      font-size: 1.8rem;
      line-height: 2.8rem;
      margin-bottom: 0.4rem;
    }

    h6 {
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 0;
    }
  }
}
