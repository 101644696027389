.aui-root {
  .no-image-placeholder {
    background: #eff1f5;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
}
