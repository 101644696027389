.aui-root {
  .submit-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3.2rem;

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 1.2rem;
    }

    button {
      width: 90%;

      @media (max-width: 640px) {
        width: 100%;
        margin: 0;
      }
    }

    &--cancel {
      margin-right: 1.2rem;
    }

    &--center {
      margin-top: 2.2rem;

      button {
        width: 50%;
        margin: 0 auto;

        @media (max-width: 640px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
