.aui-root {
  .checkbox {
    cursor: pointer;
    align-items: flex-start;

    &__text {
      margin-left: 1.2rem;
      position: relative;
      top: 0.2rem;
      margin-bottom: 2rem;

      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--input-text);
        margin: 0;
      }
    }

    &__title {
      font-weight: 500;
    }

    &__description {
      strong {
        @media (max-width: 640px) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          text-decoration: none;
        }

        @media (max-width: 360px) {
          max-width: 210px;
        }
      }
    }
  }
}
