.aui-root {
  .profile-plan {
    &__top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__text {
        h6 {
          font-weight: 600;
          color: var(--primary);
          margin-bottom: 2.4rem;
        }

        .seats-taken {
          font-size: 3rem;
          font-weight: 700;
        }

        .seats-taken-text {
          color: var(--gray-400, #7d89b0);
          margin-left: 0.8rem;
        }

        .description {
          color: var(--dark-contrast);
        }

        &__subscription-status {
          .aui-icon {
            svg {
              margin-left: -0.8rem;
            }
          }
          display: flex;
          justify-content: space-evenly;
          background-color: var(--success);
          border-radius: 1rem;
          height: fit-content;
          .subscription-status {
            color: var(--success-900, #054f31);
            margin: 0;
          }
        }

        p {
          font-weight: 500;
          color: var(--primary);
          margin: 0 0 2.4rem 0;
        }

        span {
          font-weight: 500;
          font-size: 1.6rem;
        }
      }

      svg {
        min-width: 5.6rem;
      }
    }

    &__upgrade-text {
      font-size: 4.8rem;
      line-height: 6rem;

      @media (max-width: 640px) {
        font-size: 3rem;
        line-height: 4rem;
      }
    }

    &__paid-text {
      display: flex;
      align-items: center;
    }

    &__buttons {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 3.6rem;

      @media (max-width: 640px) {
        flex-direction: column;
      }

      button {
        width: 90%;

        @media (max-width: 640px) {
          flex-direction: column;
          width: 100%;
        }
      }

      &--cancel {
        margin-right: 1.2rem;

        @media (max-width: 640px) {
          margin-right: 0;
          margin-bottom: 1.6rem;
        }
      }

      &--big {
        button {
          width: 100%;
        }
      }
    }
  }
}
