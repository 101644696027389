.aui-root {
  .help-modal {
    .help-modal-container {
      min-width: 34rem;
      background: var(--default-background);
      padding: 2rem;
      text-align: center;
      border-radius: var(--radius);
      overflow: hidden;
      background: var(--white);

      @media (max-width: 640px) {
        min-width: auto;
        max-width: 34rem;
        margin: 0 2rem;
      }

      .swiper {
        width: 34rem;

        @media (max-width: 640px) {
          width: auto;
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.4rem;

        button {
          width: 90%;
        }

        &--cancel {
          margin-right: 1.2rem;
        }
      }
    }
  }

  .swiper-pagination-bullet {
    background: #eff1f5;
    opacity: 1;
    width: 1rem;
    height: 1rem;
    margin-right: 3rem;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary);
    width: 1rem;
    height: 1rem;
  }
}
