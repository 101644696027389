.batch-select-bar-wrapper {
  z-index: 10;
  position: sticky;
  bottom: 1.2rem;
  left: 0;
  right: 0;
  display: block;
  pointer-events: none;

  &:has(.btn.icon.aui-popup-menu-button.active) {
    z-index: 5000;
  }
}
