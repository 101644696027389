.aui-root {
  .profile-modal {
    .input-phone {
      position: relative;
      align-items: flex-end;
      &__prefix {
        position: absolute;
        max-width: 6rem;
        label {
          display: none;
        }
        input {
          color: var(--primary);
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          text-align: center;

          &:disabled {
            opacity: 1;
            filter: none;
          }
        }
      }

      &__number {
        input[id="phone"] {
          margin-bottom: -1.6rem;
        }
      }
    }
  }
}
