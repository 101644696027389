.profile-summary {
  display: flex;

  &__img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: relative;
    border: 4px solid var(--white);

    @media (max-width: 1140px) {
      width: 11rem;
      height: 11rem;
    }

    @media (max-width: 1024px) {
      width: 10rem;
      height: 10rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
    }

    &--default {
      position: absolute;
      background: var(--primary);
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      p {
        font-size: 4.8rem;
        color: var(--white);
        font-weight: 700;

        @media (max-width: 1140px) {
          font-size: 4rem;
        }

        @media (max-width: 1024px) {
          font-size: 3.2rem;
        }
      }
    }

    &__edit {
      box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      background: var(--white);
      border-radius: 50%;
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      border: none;

      input {
        background: transparent;
        position: absolute;
        border-radius: 50%;
        width: 3.2rem;
        height: 3.2rem;
        opacity: 0;
      }

      @media (max-width: 1024px) {
        bottom: -0.5rem;
      }

      path {
        transition: 0.15s ease-in-out fill;
      }

      &:hover {
        path {
          fill: var(--dark);
        }
      }

      svg {
        height: 1.7rem;
        vertical-align: 0.6rem;
        position: relative;
        left: -0.1rem;
      }
    }
  }

  &__text {
    margin-left: 4.5rem;

    @media (max-width: 640px) {
      margin-left: 2rem;
    }

    @media (max-width: 450px) {
      margin-left: 0;
      margin-top: 2rem;
    }

    &.aui-column.align-left {
      @media (max-width: 450px) {
        align-items: center;
      }
    }

    h3 {
      color: var(--dark);
      font-weight: 600;
      margin-bottom: 0.5rem;

      @media (max-width: 1024px) {
        font-size: 2.6rem;
      }

      @media (max-width: 350px) {
        font-size: 2.2rem;
        margin-bottom: 0;
      }
    }

    p {
      @media (max-width: 350px) {
        font-size: 1.4rem;
      }
    }
  }

  &__email-modal {
    svg {
      path {
        stroke: var(--primary);
      }
    }
  }

  &__swiper {
    margin-bottom: -2rem;

    &--first-step {
      .swiper-wrapper {
        max-height: 120px;
      }
    }

    &--last-step {
      opacity: 0;
      height: 0;
    }

    .verify-code {
      margin-top: -2rem;
      margin-bottom: 1rem;
      border: none;
      padding: 2.2rem 0;

      input {
        min-width: 20px;
        height: 5.5rem;
        font-size: 3.2rem;
      }

      &__buttons {
        display: none;
      }
    }
  }
}
