.aui-root {
  .alert-message {
    min-height: 2rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--danger);
    opacity: 0;
    transition: 0.15s ease all;
    z-index: 10;

    position: absolute;
    bottom: 0;
    right: 2rem;
    background: var(--white);
    padding: 2rem;
    border-radius: var(--radius);
    border: 1px solid var(--light);
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
      0 2px 4px -2px rgba(16, 24, 40, 0.06);
    align-items: flex-start;
    max-width: 40rem;
    visibility: hidden;

    &--visible {
      opacity: 1;
      visibility: visible;
      bottom: 1rem;
    }

    .icon {
      position: relative;
      z-index: 1;
      align-items: center;
      justify-content: center;
      top: 0.5rem;
      height: 3rem;
      width: 3rem;
      left: -0.2rem;
      display: inline-block;

      svg {
        position: relative;
        left: 0.3rem;
        vertical-align: -1.2rem;
      }

      &:before {
        content: "";
        position: absolute;
        background: #d1fadf;
        width: 120%;
        height: 120%;
        top: 50%;
        left: 50%;
        transform: translate(-42%, -48%);
        border-radius: 50%;
        z-index: -1;
      }

      &:after {
        content: "";
        position: absolute;
        background: #ecfdf3;
        width: 170%;
        height: 170%;
        top: 50%;
        left: 50%;
        transform: translate(-44%, -48%);
        border-radius: 50%;
        z-index: -2;
      }

      &--success {
        svg {
          path {
            stroke: #039855;
          }
        }

        &:before {
          background: #d1fadf;
        }

        &:after {
          background: #ecfdf3;
        }
      }

      &--failed {
        svg {
          path {
            stroke: #d92d20;
          }
        }

        &:before {
          background: #fee4e2;
        }

        &:after {
          background: #fef3f2;
        }
      }

      &--rejected {
        svg {
          path {
            stroke: #dc6803;
          }
        }
        &:before {
          background: #fef0c7;
        }

        &:after {
          background: #fffaeb;
        }
      }
    }

    &__text {
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 2rem;
      font-size: 1.4rem;
    }

    &__title {
      font-weight: 600;
      color: var(--dark);
    }

    &__description {
      color: var(--dark-contrast);
      margin-bottom: 1rem;
    }

    &__close {
      cursor: pointer;

      svg {
        height: 1.3rem;
        vertical-align: -0.3rem;
      }
    }
  }
}
