.feature-created-modal {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 400px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--success-lighter);
    border-radius: 1rem;
    border: 1px solid #12b76a;
    color: #12b76a;

    h4 {
      margin: 0;
      font-size: 2rem;
    }

    .subtitle {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;
      color: #4b5565;
      margin: 0.5rem 0;
    }

    .aui-icon {
      font-size: 3rem;
      margin: 1rem;
    }
  }
}

.options-container {
  background-color: var(--light-contrast);
  border-radius: var(--radius);
  border: 1px solid var(--light);
  padding: 2rem;
  gap: 2rem;

  .option-btn {
    width: 100%;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1.4rem 2rem;

    .chevron {
      margin-left: auto;
      transition: 0.15s ease all;
    }

    .aui-column {
      gap: 0.8rem;
      text-align: left;
    }

    .subtitle {
      font-size: 1.4rem;
      font-weight: 400;
    }

    &:hover .chevron {
      transform: translateX(0.5rem);
    }

    &.btn.light {
      .aui-icon {
        color: var(--primary);
      }
    }
  }
}
