.image-blind {
  width: 4rem;
  height: 4rem;
  border-radius: 50px;
  position: relative;

  &__initials {
    position: absolute;
    background: var(--primary);
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

    p {
      color: var(--white);
      font-weight: 700;
    }
  }
}
