.aui-root {
  .feature-modal {
    @media (max-width: 850px) {
      align-items: flex-start;
      overflow: auto;
      height: 100%;
      min-height: 100%;
    }

    .feature-modal-aside {
      @media (max-width: 850px) {
        width: 100%;
        order: 2;
      }
      &-steps {
        @media (max-width: 850px) {
          display: none;
        }
      }

      &-wrapper {
        height: calc(100% - 3rem);

        @media (max-width: 1024px) {
          height: auto;
        }
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    .text-align-left {
      text-align: left;

      input {
        text-align: left;
      }
    }

    .upload-image-placeholder {
      background: var(--light-contrast);
      border: 1px solid var(--light);
      max-width: 100%;
      flex: 1;
      display: flex;
      margin: 0 auto;
      border-radius: 8px;

      &__description {
        max-width: 50%;

        @media (max-width: 1024px) {
          max-width: 100%;
        }
      }
    }

    .feature-modal-container {
      min-height: 90%;
      width: 83.5rem;
      background: var(--default-background);
      padding: 2rem;
      text-align: center;
      border-radius: var(--radius);
      overflow: hidden;
      background: var(--white);

      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-height: 90%;

      .submit-buttons {
        margin-top: 0;
      }

      @media (min-height: 2240px) {
        height: 100rem;
        min-height: 60%;
        max-height: 60rem;
      }

      @media (max-width: 1340px) {
        gap: 2rem;
      }

      @media (max-width: 1024px) {
        min-width: auto;
        max-width: 83.5rem;
        margin: 0 2rem;
        max-height: 90vh;
      }

      @media (max-width: 850px) {
        margin: 2rem;
        overflow: hidden;
        max-height: none;
        padding: 2rem;
      }

      @media (max-width: 320px) {
        padding: 1.4rem;
      }

      &__tag-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.8rem;
        margin: -1rem 0 1.5rem;

        &--expand {
          gap: 0.75rem;
        }
      }

      .add-new-mapping {
        overflow: scroll;
        overflow-x: hidden;

        .feature-modal-main {
          overflow: hidden;
        }
      }
    }

    form {
      display: flex;
      flex: 1;
      gap: 0;
      overflow: hidden;

      & > div {
        display: flex;
        flex-grow: 1;
      }
    }

    .form {
      overflow: hidden;

      &.project-form {
        gap: 1.2rem;

        @media (min-width: 850px) {
          overflow: auto;
        }
      }
    }

    .feature-modal-container--created {
      min-height: auto;
      width: 43.5rem;
    }

    .feature-modal--hidden {
      display: none;
    }

    &__error {
      color: var(--danger);
      margin: -1rem 0;
    }

    &__new-objects {
      background: var(--light-contrast);
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 2rem;
      gap: 2rem;
      display: flex;
      flex-direction: column;
      //min-height: 43rem;
      width: 100%;
      overflow: scroll;
      overflow-x: hidden;

      @media (max-width: 320px) {
        padding: 1.4rem;
      }

      &__btn {
        max-width: 15rem;
        margin: 0 auto;
      }
    }
  }

  .feature-modal--hidden,
  .object-modal--hidden {
    visibility: hidden;
    margin: -1rem;
  }

  .feature-modal-body {
    display: flex;
    gap: 1.2rem;
    position: relative;
    visibility: visible;
    flex: 1;

    @media (max-width: 850px) {
      flex-direction: column;
      min-height: auto;
    }

    &:before {
      content: "";
      height: 1rem;
      width: 57%;
      position: absolute;
      z-index: 1;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0,
        var(--white) 100%
      );
      opacity: 0.8;

      @media (max-width: 1024px) {
        width: 100%;
      }

      @media (max-width: 640px) {
        display: none;
      }
    }

    .aside-item-step__subtext--required {
      display: none;
    }

    &:has(input:invalid) .aside-item-step__subtext--required {
      display: inline-block;
    }
  }

  .feature-modal-main {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 60%;
    overflow-y: scroll;
    padding-bottom: 3rem;

    .feature-modal-wrapper {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding: 0;
      margin: -0.6rem;
    }

    @media (max-width: 850px) {
      width: 100%;
      order: 1;
      height: 100%;
      max-height: none;
      overflow: hidden;
      padding-bottom: 0;
    }

    &--big {
      margin-top: 2rem;
      width: 100%;
    }

    .dropzone--has-img {
      background: var(--light-contrast);
    }
  }

  .input-unit {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: end;
    gap: 0 1.5rem;

    label {
      grid-column: span 2;
      text-align: left;
      font-size: 1.4rem;
    }

    .aui-form-control {
      margin: 0;
    }
  }

  .aui-decimal,
  .aui-integer {
    input {
      max-width: initial;
    }
  }

  .feature-modal-drop {
    display: flex;
    flex-direction: column;
    width: 100%;

    .dropzone-label {
      margin-top: 0;
      text-align: left;
      color: var(--primary);
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;
      margin-bottom: 2rem;
    }

    &__amount {
      padding: 1rem;
      font-size: 1.6rem;
      margin-left: 1rem;
      line-height: 2.4rem;
      border: 1px solid var(--input-border-color);
      background: var(--white);
      color: var(--input-text);
      border-radius: 8px;
      height: 4.6rem;
      margin-bottom: -0.5rem;
    }
  }

  .feature-modal-main-wrapper {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-bottom: 1rem;
    }

    &__input-row {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      margin-top: -0.5rem;

      @media (max-width: 500px) {
        flex-direction: column;
        gap: 0;
        margin-bottom: 1.5rem;
        margin-top: 0;
      }
    }
  }

  .form .feature-modal-date-wrapper {
    display: flex;
    align-items: flex-end;

    .feature-modal-date-wrapper-input {
      min-width: 75%;
      max-width: 75%;
      width: 75%;

      input {
        border-radius: var(--input-radius) 0 0 var(--input-radius);
      }
    }

    .feature-modal-date-wrapper-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;
      min-width: 25%;
      margin: 0;
      border-radius: 0 var(--input-radius) var(--input-radius) 0;
      border: 1px solid var(--input-border-color);
      border-left: none;
      font-weight: 500;
      background-color: var(--white);
      text-transform: lowercase;
      height: 4.6rem;

      .aui-icon {
        width: 1.6rem;
        height: 1.6rem;
        color: var(--danger);

        @media (max-width: 640px) {
          width: 1.2rem;
          height: 1.2rem;
        }
        svg {
          width: 1.6rem;
          height: 1.6rem;
          vertical-align: 0;

          @media (max-width: 640px) {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
      }

      @media (max-width: 640px) {
        font-size: 1.4rem;
      }

      &:disabled {
        background: rgba(185, 192, 212, 0.02);
        svg path {
          fill: var(--input-border-color);
        }
      }
    }
  }

  .feature-modal-radio-wrapper {
    display: flex;
    gap: 3.6rem;
    margin-top: -1.2rem;

    @media (max-width: 450px) {
      label {
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
      margin-top: -0.5rem;
    }

    .aui-radio {
      @media (max-width: 768px) {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu {
    width: 19rem;

    @media (max-width: 1024px) {
      display: none;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    &__item {
      &__link {
        margin-bottom: 0.5rem;
        background: transparent;
        border: none;
        padding: 0.8rem 1.2rem;
        font-weight: 600;
        color: var(--input-text);
        font-size: 1.4rem;
        gap: 8px;
        border-radius: 8px;
        transition: 0.15s ease-in-out background, color;

        &:hover {
          background: var(--tertiary-contrast);
          color: var(--primary-dark);
        }

        &.active {
          background: var(--primary);
          color: var(--white);
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    input[type="date"]:invalid:not(:placeholder-shown),
    textarea:invalid:required {
      border-color: var(--input-border-color);
    }

    input[type="date"] {
      margin: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
    }

    textarea {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      resize: none;
      height: 128px;
    }

    .aui-form-control {
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      input {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
      }
      select {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
      }
      label {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
        color: var(--label-text);
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }

  .form-errors {
    input:invalid:required[value=""],
    input[type="date"]:invalid:not(:placeholder-shown),
    select:invalid:required,
    textarea:invalid:required {
      border: 1px solid var(--danger);
    }
  }

  // input:invalid:required[value=""],
  // input[type="date"]:invalid:not(:placeholder-shown),
  // select:invalid:required,
  // textarea:invalid:required {
  //   border: 1px solid var(--input-border-color);
  // }

  select:invalid:not(:placeholder-shown) {
    border: 1px solid var(--input-border-color);
    &:hover {
      border: 1px solid var(--primary);
    }
  }
}
