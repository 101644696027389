.aui-root {
  .step-dots {
    display: flex;
    justify-content: center;
    gap: 1.2rem;
    opacity: 1;

    &--hidden {
      opacity: 0;
      display: none;
    }

    &__dot {
      display: flex;
      justify-content: center;
      gap: 1.2rem;
      border-radius: 50%;
      border: none;
      background: var(--light);
      width: 1.2rem;
      height: 1.2rem;
      appearance: none;
      padding: 0;
      &--active {
        background: var(--primary);
      }
    }
  }
}
