.aui-root .pagetable-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  border: 1px solid var(--light);

  &:has(.pagetable-scroll-area__no-data) {
    .page-table-content-wrapper {
      height: 100%;
    }
  }

  @media (max-width: 1200px) {
    .page-table-content-wrapper {
      overflow-x: auto;
    }
  }

  @media (min-width: 1200px) {
    overflow: hidden;
    height: 100%;

    .pagetable-scroll-area {
      overflow: auto;
      height: 100%;
    }
  }

  .pagetable-tabs-wrapper {
    padding: 1rem;
    background-color: var(--white);
    height: 6.6rem;

    .custom-input-select {
      margin-bottom: 0;
    }
  }

  .pagetable-scroll-area {
    display: flex;
    flex-direction: column;
    &__no-data {
      border: 1px solid var(--light);
      border-radius: var(--radius);
    }
  }

  &:has(.pagetable-head-wrapper) .pagetable-scroll-area {
    border-top: 1px solid var(--light);
  }

  .pagetable-head-wrapper {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  .pagetable-footer {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  @media (max-width: 768px) {
    border: none;
  }

  .pagetable-content {
    display: table;
    width: 100%;

    &--no-data {
      display: flex;
      height: 100%;

      @media (max-width: 768px) {
        justify-content: center;
      }
    }
  }

  .pagetable-head {
    display: table-header-group;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--light-contrast);

    .data-cell {
      --background-color: var(--light-contrast);
      padding: 16px 12px;
    }

    .pagetable-row:last-of-type {
      &:hover {
        .data-cell {
          border-bottom: 1px solid transparent;
        }
      }
    }

    .btn-link {
      padding: 0;
      color: var(--dark);
      font-size: 14px;
      text-decoration: none;

      &.disabled:hover {
        cursor: default;
      }

      &.sortable:hover,
      &.active {
        color: var(--primary);
      }

      .sort-arrow {
        font-size: smaller;
        opacity: 0;

        &--visible {
          opacity: 1;
        }
      }
    }

    .aui-check-box {
      margin: 0 1rem;
    }
  }

  .pagetable-body {
    display: table-row-group;

    .btn {
      padding: 0.8rem;
      border: none;
      color: var(--primary);

      &:hover {
        color: var(--white);
        background-color: var(--primary-light);
      }

      &:not(:hover) {
        background-color: transparent;
      }

      &-icon-delete {
        color: var(--danger);

        &:hover,
        &:active {
          color: var(--white);
          background-color: var(--danger) !important;
        }
      }
    }

    .pagetable-row {
      background-color: var(--white);
      --background-color: var(--white);

      &:nth-child(even) {
        background-color: var(--light-contrast);
        --background-color: var(--light-contrast);
      }

      &:first-of-type {
        .data-cell {
          border-top: 1px solid var(--light);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: var(--tertiary-contrast);
        --background-color: var(--tertiary-contrast);

        &:first-of-type {
          .data-cell {
            border-top: 1px solid transparent;
          }
        }

        &:before {
          opacity: 1;
        }
      }
    }

    .pagetable-row:hover .data-cell {
      border-color: var(--primary);
    }
  }

  .pagetable-row {
    display: table-row;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -0.1rem;
      left: 0;
      right: 0;
      height: 0.1rem;
      background: var(--primary);
      transition: all ease-out 0.2s;
      opacity: 0;
      z-index: 1;
    }

    &:last-of-type {
      .data-cell {
        border-bottom: 1px solid transparent;
      }

      &:hover {
        .data-cell {
          border-bottom: 1px solid var(--primary);
        }
      }
    }
  }

  .data-cell {
    display: table-cell;
    padding: 8px 12px;
    vertical-align: middle;
    border-bottom: 1px solid var(--light);
    white-space: nowrap;
    transition: all ease-out 0.2s;

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 4px;
    }

    &.image,
    .image {
      width: 5rem;

      div {
        height: 5rem;
      }
    }

    .text {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pagetable-body-image-wrapper {
      overflow: hidden;
      max-width: 5rem;
      max-height: 5rem;
    }

    .table-select {
      min-width: 15.5rem;
    }
  }

  .data-cell.sticky {
    position: sticky;
    z-index: 1;

    .row-options {
      gap: 2rem;
      margin: 0 1rem;
    }

    &.left {
      left: 0;
      padding-right: 3rem;
      background: linear-gradient(
        90deg,
        var(--background-color) 80%,
        transparent 100%
      );
    }

    &.right {
      right: 0;
      padding-left: 3rem;
      background: linear-gradient(
        -90deg,
        var(--background-color) 80%,
        transparent 100%
      );

      .row-options {
        justify-content: flex-end;
      }
    }
  }

  .data-cell.image {
    .pagetable-body-image-wrapper img,
    .no-image-placeholder {
      width: 5rem;
      height: 5rem;
    }

    .pagetable-body-image-wrapper img {
      border: 1px solid var(--light);
      border-radius: 4px;
    }
  }

  select {
    font-size: 14px;
    padding: 4px 8px;
  }
}
