.aui-root {
  .feature-delete-modal {
    .feature-delete-modal-container {
      width: 60rem;
      background: var(--default-background);
      padding: 2rem;
      border-radius: var(--radius);
      background: var(--white);
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__title {
      color: var(--dark-contrast);
    }

    &__text {
      color: var(--dark-contrast);
    }

    &__description {
      margin-bottom: 1rem;
    }

    &__sub-text {
      margin-top: -1rem;
      margin-bottom: 1rem;
    }

    &__red-text {
      color: var(--danger);
    }

    &__info-wrapper {
      background: var(--light-contrast);
      border: 1px solid var(--light);
      width: 100%;
      border-radius: 1.2rem;
      padding: 1.2rem;
      margin-bottom: 1rem;
    }

    &__validation-wrapper {
      position: relative;

      .aui-form-control {
        margin-bottom: 0;
      }

      p {
        margin: 0 0 0.6rem;
      }

      input {
        width: 100%;
      }
    }

    &__error-msg {
      margin: 0;
      position: absolute;
      bottom: -2.8rem;
    }

    .submit-buttons {
      margin-top: 2rem;
    }
  }
}
