.aui-root {
  .location-modal-floor {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.6rem;
    border-radius: 1.2rem;
    padding: 1.4rem 1.8rem;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.1);
    border: 1px solid var(--input-border-color);
    width: 40rem;
    background: var(--white);

    .aui-form-control {
      label {
        margin-bottom: 0.5rem;
      }

      input {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
      }
    }

    .btn {
      .aui-icon {
        font-size: 1.6rem;
      }
      .text {
        margin: 0 0 0.1rem;
      }
    }

    &__room {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      width: 100%;

      &--error {
        input {
          border-color: var(--danger);
        }
      }

      &--border {
        border-bottom: 1px solid var(--light);
        margin-bottom: 1.2rem;
      }

      .btn-link.icon.danger {
        font-size: 1.6rem;
      }
    }
  }

  .location-modal-floor-wrapper {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    border-radius: 1.2rem;

    span {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 2rem;
      gap: 1rem;
    }

    .aui-text {
      @media (max-width: 768px) {
        order: 1;
      }
    }
  }

  .location-modal-floor-stairs {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.3rem;
    height: 2.3rem;
    position: relative;
    top: 0.4rem;

    @media (max-width: 768px) {
      top: 0;
    }
  }

  .location-modal-floor-chevron {
    rotate: 90deg;
    background-color: transparent;
    border: none;
  }

  .location-modal-rooms {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .aui-form-control {
    input {
      margin: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
    }
    label {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      color: var(--label-text);
      text-align: left;
    }
  }
}

@media (max-width: 500px) {
  .aui-root {
    .location-modal-floor {
      width: 100%;

      &__room {
        gap: 0.4rem;

        button {
          width: 2rem;
          height: 2rem;

          .aui-icon {
            width: 1.2rem;
            height: 1.2rem;
            color: var(--danger);
            svg {
              width: 1.8rem;
              height: 1.8rem;
              vertical-align: -0.4rem;
            }
          }
        }
      }
    }
  }
}
