.profile-card {
  display: block;

  .logo-section {
    display: flex;
    justify-content: left;
    align-items: center;

    .img-container {
      max-height: 25rem;
      max-width: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    button {
      justify-self: end;
      margin-left: auto;
      color: var(--danger);
      background-color: white;
      border: none;

      // &:hover {
      //   color: var(--danger);
      //   background-color: var(--danger-lighter);
      // }
    }
  }

  .dropzone {
    margin-top: 0;
  }
}
