.help-modal-slide {
  margin-bottom: 6rem;

  &__image {
    border-radius: var(--radius);
  }

  p {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--dark);
    line-height: 2.8rem;
  }

  span {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
