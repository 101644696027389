.aui-root {
  .feature-modal {
    .session-expire-modal-container {
      min-height: fit-content;
      width: 60rem;
      text-align: left;

      .icon-circle {
        background-color: #fffaeb;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-wrapper {
        background-color: #fef0c7;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          height: 2.5rem;
          width: 2.5rem;
          color: #dc6803;
        }
        svg {
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      p {
        margin: 0;
      }

      .btn-close {
        margin-top: 1.5rem;
      }
    }
  }
}
