.organisation-box-container {
  width: 33.333%;
  padding: 1.2rem;

  @media (min-width: 2240px) {
    width: 25%;
  }

  @media (max-width: 1024px) {
    padding: 0.8rem;
    width: 50%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }

  [aria-disabled="true"]:hover {
    cursor: not-allowed;
  }

  .organisation-box {
    background: var(--white);
    border-radius: 12px;
    padding: 2rem 2.5rem;
    border: var(--input-border-width) solid var(--input-border-color);
    height: 100%;
    transition: 0.2s ease-in-out all;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1024px) {
      padding: 1.4rem 2rem;
    }

    &:hover:not([aria-disabled="true"]) {
      background: var(--input-border-color-single);

      .organisation-box__top,
      .organisation-box__description {
        top: -0.5rem;
      }
    }

    &__image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }

    &__top {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      justify-content: space-between;
      top: 0;
      transition: 0.2s ease-in-out top;

      span {
        width: 4rem;
        height: 4rem;

        svg {
          height: 4rem;
        }
      }

      &__text {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .org-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;
        margin-top: -3rem;
        padding-left: 1rem;
        height: 2rem;

        .text {
          margin: 0 0 0 0.5rem;
          color: var(--primary);
          transition: 0.15s ease-in-out color;
        }

        svg {
          height: 1.9rem;
          width: 1.9rem;
          transition: 0.15s ease-in-out all;

          path {
            fill: var(--primary);
          }
        }

        &:hover {
          text-decoration: underline;

          svg path {
            fill: var(--primary-contrast);
          }

          .text {
            color: var(--primary-contrast);
          }
        }
      }
    }

    &__title {
      margin-left: 1.5rem;
      color: var(--dark);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 23rem;

      &--skeleton {
        margin-top: 2.1rem;
      }
    }

    &__description {
      color: var(--input-color);
      margin: 2rem 0 0 0;
      position: relative;
      top: 0;
      transition: 0.2s ease-in-out top;

      &--skeleton {
        margin-top: 3.5rem;
      }
    }
    &__inactive {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      &__text {
        margin: 0;
        color: #d92d20;
      }

      a {
        &:hover {
          p {
            text-decoration: underline;
            text-decoration-color: var(--primary);
          }
        }

        p {
          margin: 0;
        }
      }
    }
    &__pending-approval {
      margin: 0;
      color: var(--warning);
    }

    &__create-text {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-weight: 600;
        color: var(--white);
        margin: 0 0 -0.8rem 1rem;
      }
    }

    &__rocket {
      position: absolute;
      bottom: 0;
      right: 0;

      &__icon {
        width: 8rem;
        height: 8rem;
        position: absolute;
        right: 2.95rem;
        bottom: 2.5rem;
        transition: 0.2s ease-in-out bottom;

        svg {
          height: 8rem;

          path {
            transition: 0.2s ease-in-out fill;
          }
        }
      }

      &__cloud {
        width: 5rem;
        height: 5rem;
        position: absolute;
        right: 18rem;
        bottom: -0.7rem;
        transition: 0.2s ease-in-out bottom;

        svg {
          height: 5rem;
        }
      }
    }

    &--create {
      background: var(--primary);
      position: relative;
      overflow: hidden;
      display: flex;
      min-height: 130px;
      justify-content: center;

      &:hover:not([aria-disabled="true"]) {
        background: var(--primary-light);

        .organisation-box__rocket__icon {
          bottom: 3.2rem;

          path {
            &:nth-of-type(9) {
              fill: var(--primary-light);
            }
          }
        }

        .organisation-box__rocket__cloud {
          bottom: 0;
        }
      }
    }
  }
}
