.register-content {
  display: flex;
  width: 50%;
  padding: 0;
  margin: 0;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
  }

  &--with-copyrights {
    flex-direction: column;
  }

  &__items {
    display: flex;
    align-self: center;
    flex-grow: 1;
  }
}
