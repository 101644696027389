.verify-code {
  border: 1px solid #b9c0d4;
  border-radius: var(--radius);
  padding: 2.2rem 2.4rem;
  margin-top: 34px;

  @media (max-width: 450px) {
    padding: 1.8rem;
  }

  &__resend {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: -1rem;

    p {
      margin-right: 0.3rem;
      font-size: 1.4rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3.4rem;
    position: relative;

    button {
      width: 90%;
    }

    &--cancel {
      margin-right: 1.2rem;
    }
  }

  &__wrong-code {
    position: absolute;
    z-index: 1;
    top: -5.5rem;
    left: 0;

    p {
      font-size: 1.4rem;
      color: var(--danger);
    }
  }

  &__otp {
    input[type="number"] {
      width: 8rem !important;
      height: 8rem;
      border-radius: var(--radius);
      margin-right: 1.2rem;
      font-size: 4rem;
      font-weight: 700;
      transition: 0.15s all;

      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 1024px) {
        width: 6.5rem !important;
        height: 6.5rem;
      }

      @media (max-width: 350px) {
        font-size: 3.2rem;
        width: 5.5rem !important;
        height: 5.5rem;
      }
    }

    &--big {
      input[type="number"] {
        width: 6.5rem !important;
        height: 6.5rem;
        border-radius: var(--radius);
        margin-right: 1.2rem;
        font-size: 4rem;
        font-weight: 700;
        transition: 0.15s all;

        &:last-of-type {
          margin-right: 0;
        }

        @media (max-width: 1024px) {
          font-size: 3.2rem;
          width: 5rem !important;
          height: 5rem;
        }

        @media (max-width: 640px) {
          font-size: 2.4rem;
          padding: 1rem;
        }

        @media (max-width: 450px) {
          font-size: 2.4rem;
          padding: 0.5rem;
          width: 4rem !important;
          height: 4rem;
        }
      }
    }

    :not(input[value=""]) {
      border-color: var(--primary);
      color: var(--primary);
    }

    &--filled {
      input[type="number"],
      input[type="number"]:focus {
        border-color: var(--primary);
        color: var(--primary);
      }
    }
    &--error {
      input[type="number"],
      input[type="number"]:focus {
        border-color: var(--danger);
        color: var(--danger);
      }
    }
  }
}
