.add-new-link {
  background: var(--white);
  border: 1px solid var(--light);
  padding: 2.4rem;
  border-radius: 16px;
  margin-bottom: 2.4rem;
  cursor: pointer;
  transition: 0.15s ease-in-out all;

  &__icon {
    svg {
      height: 2.5rem;
      width: 2.5rem;

      path {
        fill: var(--primary);
      }
    }
  }

  h4 {
    color: var(--dark);
    font-weight: 700;
    margin-top: 5rem;
    margin-bottom: 1rem;
    position: relative;
    transition: 0.15s ease-in-out top;
    top: 0;

    @media (max-width: 1200px) {
      margin-top: 3rem;
    }
  }
  h6 {
    color: var(--input-text);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 2.4rem;
    position: relative;
    transition: 0.15s ease-in-out all;
    top: 0;
  }

  &:hover {
    border-color: var(--primary);
    background: var(--input-border-color-single);

    h4,
    h6 {
      top: -1rem;
    }
  }
}
