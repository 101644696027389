.aui-root {
  .horizontaltabs-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2.4rem;
    padding: 0.6rem 2.4rem;
    border-radius: 8px;
    border: 1px solid var(--gray-200-light, #e3e8ef);
    background: var(--gray-50, #f8fafc);

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: baseline;
      gap: 0.2rem;
      padding: 0.6rem 0.8rem;
    }
  }

  .horizontaltabs-item {
    border: none;
    display: flex;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 6px;
    background-color: transparent;
    box-shadow: none;
    height: 100%;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.8rem;
    color: var(--input-text);
    transition: 0.2s ease-in-out all;
    text-decoration: none;

    @media (max-width: 800px) {
      width: 100%;
      text-align: center;
      flex: 1;
    }

    &:hover {
      color: var(--primary);
    }

    &:disabled {
      pointer-events: none;
    }
    &.active,
    &--active {
      background-color: var(--white);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
        0 1px 3px 0 rgba(16, 24, 40, 0.1);

      color: var(--primary);
    }
  }
}
