.copyrights {
  align-self: flex-end;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1.5rem 2.5rem;
  }

  p,
  a {
    font-size: 1.2rem;
    color: var(--inputTextColor);
    margin: 0;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    .aui-icon {
      height: 1.2rem;
      width: 1.2rem;

      svg {
        height: 1.2rem;
        vertical-align: 0.4rem;
      }
    }
    a {
      margin-left: 1.4rem;
      text-decoration: none;
    }
  }
}
