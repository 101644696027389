.help-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7.2rem;
}

.help-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 76.8rem;
  width: 100%;
}

.aui-root .help-header {
  margin: 1.2rem 0 2.4rem 0;
}

.aui-root .help-subheader {
  color: var(--dark-contrast);
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
}

.aui-root .help-line {
  background: #6239d9;
  height: 0.2rem;
  margin-top: 3.6rem;
  margin-bottom: 2.4rem;
}

.help-intouch-wrapper {
  border-radius: 16px;
  background: var(--base-white, #fff);
  display: flex;
  justify-content: space-between;
  padding: 3.2rem;
  gap: 7rem;
  width: 100%;
  margin-bottom: 4.8rem;
  border: 1px solid var(--light);
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.3);

  button {
    display: flex;
    padding: 1.2rem 2rem;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary);
    background: var(--primary);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--white);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
  }
}

.help-intouch-text-wrapper {
  display: flex;
  flex-direction: column;
}

.help-questions-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
}

.help-question-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 3.2rem;
  border-radius: 16px;
  border: 1px solid var(--light);
  background-color: var(--base-white, #fff);
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.2);

  button {
    position: absolute;
    background-color: transparent;
    border: none;
    top: 3.2rem;
    right: 3.2rem;
  }
}

.help-question-wrapper.open {
  border: 1px solid var(--primary);
}

.help-question-text-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}

.help-question-text-subheader {
  color: var(--dark-contrast);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  max-height: 0;
  overflow: hidden;
  height: 100%;
  transition: max-height 0.15s ease-in;
}

.help-question-text-subheader--visible {
  max-height: 50rem;
  transition: max-height 1s ease-out;
  transition-delay: 0.25s;
}

span.help-question-skeleton-button {
  margin-right: 0.4rem;
  background-color: var(--light);
}

span.help-question-skeleton-title {
  background-color: var(--light);
}

details {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

summary {
  cursor: pointer;
  list-style: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
  color: var(--dark);
}
