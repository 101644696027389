.aui-root {
  .score-calculator {
    border: 1px solid var(--light);
    background: var(--white);
    display: inline-flex;
    border-radius: var(--radius);
    overflow: hidden;
    gap: 0;

    &__value {
      width: 3.5rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid var(--light);
      transition: 0.2s ease-in-out all;
      cursor: pointer;

      @media (min-width: 2000px) {
        width: 4rem;
      }

      @media (min-width: 2150px) {
        width: 5rem;
      }

      @media (min-width: 2700px) {
        width: 6.5rem;
      }

      @media (min-width: 3100px) {
        width: 8.5rem;
      }

      @media (max-width: 1750px) {
        width: 3rem;
        height: 3rem;
      }

      @media (max-width: 768px) {
        width: 5rem;
        height: 5rem;
      }

      @media (max-width: 380px) {
        width: 4rem;
        height: 4rem;
      }

      &:hover {
        background: var(--input-border-color-single);
      }

      &.active {
        background: var(--primary);
        color: var(--white);
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }
}
