.auth-change-language {
  position: absolute;
  top: 3rem;
  right: 3rem;

  @media (max-width: 640px) {
    top: 2rem;
    right: 2rem;
  }

  &__option {
    &__language {
      display: inline-block;
      margin-left: 0.8rem;
    }
  }

  .language-select {
    cursor: pointer;
    width: 9.5rem;

    &__value-container {
      padding: 0.5rem 0.5rem 0.5rem 1.8rem;
      font-weight: 600;
      font-size: 1.6rem;
    }

    &__indicator {
      padding: 0.5rem 1.8rem 0.5rem 0;
    }

    &__control {
      border-radius: var(--input-radius);
      height: 4.3rem;
      padding-left: 0;

      svg {
        fill: var(--medium-contrast);
      }
    }

    &__single-value {
      color: var(--medium-contrast);

      .auth-change-language__option {
        &__icon {
          display: none;
        }
        &__language {
          margin-left: 0;
        }
      }
    }

    &__menu {
      margin-top: 1rem;
      border: 1px solid var(--input-border-color);
      border-radius: var(--input-radius);
      box-shadow: none;
    }

    &__option {
      svg {
        width: 1.8rem;
        height: 1.8rem;
        vertical-align: -0.28rem;
      }

      &--is-focused {
        background: var(--light);
      }

      &--is-selected {
        background: var(--tertiary-contrast);
      }
    }
  }
}
