.profile-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2rem;

  &--disabled {
    pointer-events: none;
  }

  &__text {
    p {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
    }

    span {
      font-size: 1.4rem;
      color: rgba(var(--default-text), 0.6);
    }

    div {
      margin-bottom: 0;
      font-weight: 400;
      margin-top: 1.2rem;
    }
  }

  &__edit {
    font-size: 1.4rem;
    color: var(--primary);
    font-weight: 600;
  }
}
