.aui-root {
  .elements-counter {
    display: flex;

    &__btn {
      border: 1px solid var(--input-border-color);
      background: var(--white);
      transition: 0.2s ease-in-out background-color;
      padding: 1rem;

      &:hover {
        background: var(--tertiary-contrast);
      }

      &:disabled {
        pointer-events: none;
      }

      &--minus {
        border-radius: 8px 0 0 8px;
        border-right: none;
      }

      &--plus {
        border-radius: 0 8px 8px 0;
        border-left: none;
      }
    }

    .counter-container {
      display: flex;
      flex-direction: row;
      flex: 2 1 auto;
      padding: 1rem;
      border: 1px solid var(--input-border-color);
      width: 15rem;
      gap: 0.5rem;
      align-items: center;

      .aui-form-control.aui-integer {
        margin: 0;
        width: fit-content;

        input {
          text-align: right;
          color: var(--primary);
          font-weight: 500;
          font-size: 2rem;
          border-radius: 0;
          padding: 0 0;
          border: none;
          flex: 1;
          min-width: initial;

          display: flex;
          width: 100%;
        }
      }

      &__quantity-unit {
        width: fit-content;
        text-align: left;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.4rem;
        border-radius: 0;
        padding: none;

        border-left: none;
      }
    }
  }
}
