.object-mapping-modal {
  max-height: 90svh;
  overflow: hidden;
  width: 500px;
  max-width: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;

  padding: 2rem;

  form {
    display: grid;
    gap: 2rem;
    height: 100%;
    overflow: auto;
  }

  .aui-form-control.aui-integer input {
    max-width: initial;
    text-align: left;
  }

  .buttom-buttons .btn {
    flex: 1 1 0;
  }
}
