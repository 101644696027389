.aui-root {
  .error-message {
    min-height: 2rem;
    margin: 1rem 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--danger);
    opacity: 0;
    transition: 0.15s ease-in-out opacity;

    &--visible {
      opacity: 1;
    }
  }
}
