.aui-root {
  .text-button {
    color: var(--primary);
    text-decoration: underline;
    transition: 0.15s color;
    cursor: pointer;

    &:hover {
      color: var(--defaultTextColor);
    }

    &--disabled {
      pointer-events: none;
      color: var(--disabled);
    }

    &--dark {
      font-weight: 600;
      color: var(--dark);
      text-decoration: none;
      font-size: 1.4rem;
    }
  }
}
