.aui-root {
  .select {
    .table-select {
      background-color: var(--white);
      border: 1px solid var(--primary);
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      color: var(--primary);
      cursor: pointer;
      appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.200408 0.739665C0.48226 0.436132 0.956809 0.418556 1.26034 0.700408L5 4.22652L8.73967 0.700408C9.0432 0.418556 9.51775 0.436132 9.7996 0.739665C10.0815 1.0432 10.0639 1.51775 9.76034 1.7996L5.51034 5.7996C5.22258 6.0668 4.77743 6.0668 4.48967 5.7996L0.239665 1.7996C-0.0638681 1.51775 -0.081444 1.0432 0.200408 0.739665Z' fill='%236239D9'/></svg>");
      background-repeat: no-repeat;
      background-position: bottom 49% right 1rem;
      min-width: 9rem;

      &:hover {
        background-color: var(--inputBorderColorSingle, #ebedfb);
      }

      &:focus {
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }
  }
}
