.aui-root {
  .feature-main-title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-align: left;
    color: var(--primary);
    width: 100%;
    display: inline-block;
    margin-bottom: 2rem;
  }
}
