.add-new-sidebar {
  display: flex;
  flex-direction: column;
  background: #fcfcfd;
  width: 44rem;
  min-height: 100%;
  left: 28rem;
  position: fixed;
  box-shadow: 9px 2px 27px -36px rgba(0, 0, 0, 0.8);
  transition: 0.15s ease-in-out all;
  border-left: 1px solid var(--light);
  border-right: 1px solid var(--light);
  z-index: -2;
  overflow: scroll;
  max-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    border-left: none;
    left: 0;
    width: 100%;
    overflow: scroll;
    max-height: 100vh;
    z-index: 1;
  }

  &--collapse {
    width: 0;
    border-right: none;
  }

  &--sidebar-collapse {
    left: 9.2rem;

    @media (max-width: 1024px) {
      left: 0;
    }
  }

  &__top {
    padding: 3.1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--light);

    @media (max-width: 1450px) {
      padding: 2.8rem 2rem;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
      color: var(--dark);
      font-weight: 600;
      line-height: 3rem;
      font-size: 2rem;
      margin: 0 0 0 3rem;
    }

    svg {
      height: 2rem;
      position: relative;
      top: -0.2rem;
      path {
        fill: var(--primary);
      }
    }
  }

  &__add-icon {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: var(--link-text);
      width: 190%;
      height: 190%;
      top: 50%;
      left: 50%;
      transform: translate(-42%, -48%);
      border-radius: 50%;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      background: var(--tertiary-contrast);
      width: 280%;
      height: 280%;
      top: 50%;
      left: 50%;
      transform: translate(-44%, -48%);
      border-radius: 50%;
      z-index: -2;
    }
  }

  &__close-icon {
    cursor: pointer;
    path {
      transition: 0.15s ease-in-out stroke;
    }
    &:hover {
      path {
        stroke: var(--primary);
      }
    }
  }

  &__center {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2.4rem;
    display: flex;
    opacity: 0;
    transition: 0.15s ease-in-out opacity;

    &--show {
      opacity: 1;
    }
  }
}
