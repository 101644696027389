.enter-code {
  &__email {
    text-align: center;
    h4 {
      font-size: 2.4rem;
      color: var(--dark);
      font-weight: 700;
      margin: 0;

      @media (max-width: 640px) {
        font-size: 2rem;
      }
    }
  }
}
