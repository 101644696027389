.aui-root {
  .login-banner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 8rem 4rem;

    @media (max-width: 1024px) {
      display: none;
    }

    &__image {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        height: 100%;
        width: 100%;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0, #0000004a 0%, #00000042 100%);
      }
    }

    &__text {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      &__icon {
        position: absolute;
        width: 8rem;
        height: 8rem;
        top: -10rem;

        svg {
          height: 8rem;
        }
      }

      &__become-part {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        p {
          margin-left: 2rem;
        }
      }

      &__title {
        margin: 0;
        font-weight: 700;
        line-height: 6rem;

        @media (max-width: 1440px) {
          font-size: 4rem;
          line-height: 5.2rem;
        }
      }

      &__description {
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 500;
        color: var(--white);
        position: relative;
        z-index: 2;
      }
    }
  }
}
