.aui-root {
  .language-select {
    width: 100%;
    height: 4.4rem;
    font-size: 1.6rem;

    &__control {
      padding-left: 0.5rem;
      border-color: var(--input-border-color);

      svg {
        height: 2rem;
        vertical-align: -0.4rem;
      }

      p {
        font-size: 1.6rem;
      }
    }

    &__control {
      border-color: var(--input-border-color);
    }

    &--no-border {
      padding-left: 0.5rem;
      border-color: var(--input-border-color);
      box-shadow: none;

      &:hover {
        border-color: var(--input-border-color);
      }
    }

    &__indicator {
      &--separator {
        display: none;
      }
    }

    &__option {
      color: var(--dark);
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;

      &--is-focused {
        background: transparent;
      }

      &:hover {
        background-color: var(--light-contrast);
      }

      &:active {
        background-color: var(--tertiary-contrast);
      }

      &--is-selected {
        background: var(--tertiary-contrast);
        font-weight: 500;
        color: var(--dark);

        &:hover,
        &:active {
          background-color: var(--tertiary-contrast);
        }
      }
    }

    &__menu {
      border-radius: var(--radius);
    }

    &__menu-list {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: var(--radius);
    }
  }
}
