.aui-root {
  .assessment {
    background: var(--white);
    padding: 0.3rem 2.4rem;
    border: 1px solid var(--light);
    border-radius: var(--radius);
    transition: all 0.15s ease-out;

    &:hover {
      background: var(--tertiary-contrast);
      border-color: var(--primary);
    }

    &--active {
      padding-bottom: 2.4rem;
    }

    align-items: flex-start;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 1rem;
    }

    &__title {
      margin-left: 2rem;
      width: 25rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 18rem;
      }

      @media (max-width: 350px) {
        width: 10rem;
      }
    }

    &__chevron {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer !important;
      padding: 1.2rem 0;
      min-width: 10rem;

      @media (max-width: 768px) {
        min-width: auto;
      }

      p {
        color: var(--primary);
        margin-right: 1rem;

        @media (max-width: 768px) {
          display: none;
        }
      }

      svg {
        vertical-align: 0.5em;
        transition: 0.2s ease-in-out all;
        path {
          stroke: var(--primary);
          transition: 0.2s all;
        }
      }

      &:hover {
        svg path {
          stroke: var(--primary);
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--default-spacing, 5px);
      cursor: pointer;

      &__details {
        margin-right: 10rem;
      }

      &__status {
        margin-left: 2rem;
        gap: 1.5rem;

        @media (max-width: 1600px) {
          flex-wrap: wrap;
          flex-direction: initial;
          margin-left: 0;
          gap: 0.8rem;
        }

        @media (max-width: 1100px) {
          display: none;
        }
      }
    }

    &__image-wrapper {
      height: 100%;
      width: 100%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &__image {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        .assessment__image__btn {
          opacity: 1;
          visibility: visible;
        }
      }

      &__warning {
        display: flex;
        align-items: center;
        padding: 1.2rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        z-index: 5;
        border-radius: 8px;

        svg {
          height: 2rem;
          width: 2rem;
          top: -0.3rem;
          position: relative;
        }

        p {
          margin: 0 0 0 1.2rem;
        }
      }

      &--mapping {
        width: 26rem;
        border-radius: 8px;
        height: 24rem;
        border: 1px solid var(--light);

        &__img {
          width: 100%;
          height: 100%;
        }

        @media (min-width: 2100px) {
          width: 15%;
        }

        @media (max-width: 1900px) {
          width: 28rem;
          height: 28rem;
        }

        @media (max-width: 1600px) {
          width: 32%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &__btn {
        position: absolute;
        z-index: 10;
        top: calc(50% - 2rem);
        opacity: 0;
        visibility: hidden;
      }
    }

    &__bottom {
      margin-top: 1.5rem;
      gap: 1.2rem;

      @media (max-width: 1600px) {
        flex-direction: initial;
        flex-wrap: wrap;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .assessment__no-image {
        position: relative;
        width: 28rem;
        height: 28rem;

        .no-image-placeholder {
          width: 100%;
        }

        @media (min-width: 2100px) {
          width: 15%;
        }

        @media (max-width: 1600px) {
          width: 32%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    &__gray-wrapper {
      background: #fcfcfd;
      border-radius: var(--radius);
      padding: 1.2rem;
      border: 1px solid var(--light);
      align-self: normal;
    }

    &__white-wrapper {
      background: var(--white);
      border-radius: var(--radius);
      padding: 1.2rem;
      border: 1px solid var(--light);
      flex: 1;
      width: 100%;

      &__title-left {
        line-height: 2rem;
        align-self: flex-start;
        margin-bottom: 0.5rem;
      }

      .object-status {
        align-self: flex-start;
      }

      .item-quantity {
        padding: 0.1rem 3rem;
        margin: 0;
        border-radius: 16px;
        background: var(--white);
        color: var(--primary-contrast);
        align-self: flex-start;
        border: solid 1px var(--primary);
      }
    }

    &__calc-info {
      margin-top: 2rem;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      gap: 2rem;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
      }

      &__switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #b9c0d4;
        padding: 1rem;
        background: var(--white);
        border-radius: var(--radius);
        cursor: pointer;
        transition: 0.2s ease-in-out all;

        &:hover {
          border-color: var(--primary);
          background: var(--input-border-color-single);
        }

        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }

        p {
          margin: 0 1rem 0;
          white-space: nowrap;
        }
      }
    }

    &__calculator {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      flex: 1.2;
      justify-content: space-between;

      @media (max-width: 1600px) {
        order: 2;
        flex: initial;
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 1rem;
      }

      &--spacing {
        gap: 1.2rem;
      }

      &__score {
        width: 31%;
        align-items: flex-start;
        padding: 1.2rem;
        flex: 1;
        border: 1px solid transparent;

        &.assessment__white-wrapper {
          border: 1px solid var(--light);

          @media (max-width: 1900px) {
            margin: 0;
          }
        }

        @media (min-width: 1920px) {
          flex: initial;
        }

        @media (max-width: 1900px) {
          width: 45%;
          margin: -1.2rem 0;
        }
        @media (max-width: 1600px) {
          width: 32.3%;
          flex: initial;
        }

        @media (max-width: 1250px) {
          width: 31.6%;
        }

        @media (max-width: 768px) {
          padding: 1rem;
          flex: 1;
          width: 100%;
        }

        &__top-row {
          justify-content: space-between;
          width: 100%;

          @media (max-width: 768px) {
            margin-bottom: 1rem;
          }
        }

        &__text {
          min-width: 20rem;
          margin-top: auto;
          color: var(--primary);

          @media (max-width: 1700px) {
            min-width: 17rem;
          }
        }

        .score-calculator {
          margin-top: 1rem;
          @media (max-width: 768px) {
            align-self: center;
          }
        }
      }
    }

    &__total-score {
      justify-content: center;
      width: 24rem;
      gap: 1.2rem;

      @media (min-width: 1920px) {
        width: 15%;
      }

      @media (max-width: 1750px) {
        width: 15%;
      }

      @media (max-width: 1600px) {
        width: 32%;
      }

      @media (max-width: 768px) {
        width: 100%;
        order: 1;
      }

      &__top {
        justify-content: center;
        align-self: center;
      }

      .aui-form-control,
      .custom-input-select {
        margin-bottom: 0;
      }
    }

    &__mapping {
      &__details {
        width: 24rem;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        @media (min-width: 1600px) {
          width: 15%;
        }

        @media (max-width: 1600px) {
          width: 32%;
          flex: 1;
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        .assessment__white-wrapper {
          justify-content: space-between;
        }
      }
    }

    &__invalid-score {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;

      svg {
        height: 2rem;
        width: 2rem;
        margin-left: -0.4rem;

        path {
          fill: var(--warning);
        }
      }

      &__text {
        text-align: center;
        color: var(--warning);
        margin: 2rem 0 0 0;
        @media (max-width: 768px) {
          margin: 1rem 0 0 0;
        }
      }
    }

    &__tooltip {
      background: transparent;
      padding: 0;

      svg {
        height: 1.8rem;
        vertical-align: -0.5rem;

        path {
          fill: #344054b2;
        }
      }
    }
  }
}
