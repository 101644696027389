.nav-link {
  display: flex;
  align-items: center;

  p {
    font-size: 1.4rem;
    margin: 0;

    a {
      text-decoration: none;
      font-weight: 600;
      margin-left: 0;
      font-size: 1.4rem;
    }
  }
}
