.page-container {
  .errorpage-container {
    width: 100%;
    display: grid;
    background: var(--inputBorderColorSingle);
    z-index: 0;
    overflow: hidden;

    .logo {
      padding: 3.6rem 0 0 3.4rem;
      position: absolute;

      .sidebar__top__logo {
        right: 2rem;
        top: -1.5rem;
        left: auto;
      }
    }

    .text-box {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-self: center;
      align-self: center;
      max-height: fit-content;
      max-width: 85rem;
      color: var(--primary);
      z-index: 20;

      @media (max-width: 850px) {
        padding: 0 2.4rem;
      }

      .seperation-line {
        width: 25rem;
        height: 0.2rem;
        background-color: var(--primary);
        margin: 2.4rem;

        @media (max-width: 850px) {
          margin: 1.6rem;
        }
      }

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        gap: 1.2rem;
        text-align: center;

        h2,
        h4 {
          font-size: 3.6rem;
          line-height: 4.4rem;
          margin: 0;
          color: var(--primary);

          @media (max-width: 850px) {
            font-size: 2.4rem;
            line-height: 3rem;
          }
        }

        h2 {
          font-weight: 700;
        }

        h4 {
          font-weight: 500;
        }
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2rem;

        @media (max-width: 850px) {
          gap: 1.6rem;
        }
        p {
          margin: 0;
          font-size: 2rem;
          line-height: 3rem;
          font-weight: 500;

          @media (max-width: 850px) {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }
        }
      }

      .btn {
        margin-top: 4.8rem;

        @media (max-width: 850px) {
          margin-top: 2.4rem;
        }
      }
    }

    .image-container {
      position: relative;

      @media (max-width: 850px) {
        display: none;
      }

      img {
        position: absolute;

        &.teacan {
          z-index: 10;
          max-width: 15rem;
          top: 5%;
          left: 25%;
        }

        &.chair {
          z-index: 10;
          max-width: 30rem;
          bottom: 0;
          right: 0;
        }

        &.sofa {
          z-index: 10;
          max-width: 40rem;
          top: 0;
          right: 0;
        }

        &.closet {
          z-index: 10;
          max-width: 30rem;
          bottom: 5%;
          left: 0;
        }
      }
    }

    .circle {
      background: linear-gradient(
        141.86deg,
        rgba(98, 57, 217, 0.1) -7.26%,
        rgba(98, 57, 217, 0) 74.97%
      );
      z-index: 2;
      border: 3px solid var(--white);

      @media (max-width: 1400px), (max-height: 760px) {
        display: none;
      }

      &.large,
      &.medium,
      &.small {
        border-radius: 50%;
        position: absolute;
      }

      &.large {
        width: 200rem;
        height: 200rem;
        transform: rotate(88deg);
        top: -10rem;
        left: -100rem;
      }

      &.medium {
        width: 60rem;
        height: 60rem;
        transform: rotate(-93deg);

        top: -10rem;
        right: -10rem;
      }

      &.small {
        width: 20rem;
        height: 20rem;
        transform: rotate(-26deg);

        bottom: 5rem;
        right: 6rem;
      }
    }

    .copyrights {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-self: end;
      max-height: fit-content;
      max-width: 85rem;
      color: var(--gray-900, #111322);
      z-index: 20;

      p {
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 500;
      }
    }
  }
}
