.aui-root {
  .multiselect-wrapper {
    position: relative;
  }
  .multi-select {
    width: 100%;
    min-height: 4.4rem;
    font-size: 1.6rem;
    position: relative;

    &__custom-icon {
      position: absolute;
      top: 50%;
      right: 1.2rem;
      transform: translateY(-50%);

      svg {
        vertical-align: 0;
      }
    }

    &__control {
      padding-left: 0.5rem;
      border-color: var(--input-border-color);
      border-radius: var(--radius);
      min-height: 4.6rem;

      svg {
        height: 2rem;
        vertical-align: -0.4rem;
      }

      p {
        font-size: 1.6rem;
      }
    }

    &__control {
      border-color: var(--input-border-color);
    }

    &__input-container {
      text-align: left;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      color: var(--input-text);
    }

    &__placeholder {
      text-align: left;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      color: var(--input-text);
    }

    &__value-container {
      padding-left: 0;
      padding-right: 3rem;
    }

    &__multi-value {
      height: 2.6rem;
      display: flex;
      align-items: center;
      border-radius: 6px;
      background: transparent;
      border: 1px solid var(--input-border-color);
      overflow: hidden;
      padding: 0.2rem 0.5rem;

      &__remove {
        cursor: pointer;

        &:hover {
          background: transparent;

          svg {
            fill: var(--text-color);
          }
        }

        svg {
          fill: #7d89b0;
        }
      }

      &__label {
        height: 2.6rem;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2rem;
      }
    }

    &--no-border {
      padding-left: 0.5rem;
      border-color: var(--input-border-color);
      box-shadow: none;

      &:hover {
        border-color: var(--input-border-color);
      }
    }

    &__indicator {
      display: none;

      &--separator {
        display: none;
      }
    }

    &__option {
      color: var(--input-text);
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: left;
      padding: 1rem 1.6rem;

      &--is-focused {
        background: transparent;
      }

      &:hover {
        background-color: var(--light-contrast);
      }

      &:active {
        background-color: var(--tertiary-contrast);
      }

      &--is-selected {
        background: var(--tertiary-contrast);
        font-weight: 500;
        color: var(--dark);

        &:hover,
        &:active {
          background-color: var(--tertiary-contrast);
        }
      }
    }

    &__menu {
      border-radius: var(--radius);
    }

    &__menu-list {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: var(--radius);
    }
  }
}
