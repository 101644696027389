.aui-root {
  .text-switch {
    gap: 0;

    &__option {
      padding: 1rem 1.6rem;
      margin: 0;
      border: 1px solid var(--light);
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      cursor: pointer;
      transition: 0.15s ease-in-out background;
      background: var(--white);

      &:hover {
        background: var(--link-text);
      }

      &--active {
        font-weight: 600;
        background: var(--link-text);
      }

      &:not(:last-of-type) {
        border-right: none;
      }

      &:first-of-type {
        border-top-left-radius: var(--radius);
        border-bottom-left-radius: var(--radius);
      }

      &:last-of-type {
        border-top-right-radius: var(--radius);
        border-bottom-right-radius: var(--radius);
      }
    }
  }
}
