.aui-root {
  .title {
    font-size: 4.8rem;
    line-height: 6rem;
    letter-spacing: -0.96px;
    font-weight: 600;
    color: var(--dark);
    display: inline-block;

    &--bold {
      &--500 {
        font-weight: 500;
      }
      &--600 {
        font-weight: 600;
      }
      &--700 {
        font-weight: 700;
      }
      &--800 {
        font-weight: 800;
      }
    }

    &--size {
      &--xs {
        font-size: 2.4rem;
        line-height: 3.2rem;
        letter-spacing: normal;
      }
      &--sm {
        font-size: 3rem;
        line-height: 3.8rem;
        letter-spacing: normal;
      }
      &--md {
        font-size: 3.6rem;
        line-height: 4.4rem;
        letter-spacing: -0.72px;
      }
      &--lg {
        font-size: 4.8rem;
        line-height: 6rem;
        letter-spacing: -0.96px;
      }
      &--xl {
        font-size: 6rem;
        line-height: 7.2rem;
        letter-spacing: -1.2px;
      }
      &--2xl {
        font-size: 7.2rem;
        line-height: 9rem;
        letter-spacing: -1.44px;
      }
    }

    &--color {
      &--dark {
        color: var(--dark);
      }
      &--dark-contrast {
        color: var(--dark-contrast);
      }
      &--light {
        color: var(--light);
      }
      &--light-contrast {
        color: var(--light-contrast);
      }
      &--primary {
        color: var(--primary);
      }
      &--primary-contrast {
        color: var(--primary-contrast);
      }
    }
  }
}
