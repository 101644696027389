.aui-root {
  .object-state-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: var(--radius);
    border: 1px solid var(--light);
    background: var(--white);
    overflow: hidden;

    flex-grow: 1;

    &:hover {
      border-color: var(--primary);
      background-color: var(--tertiary-contrast);
    }

    summary {
      display: flex;
      align-items: center;
      padding: 1.6rem;
      gap: 1rem;
      color: var(--default);

      .object-state-info__item-count {
        color: var(--primary);
      }
    }

    .chevron {
      transform: rotate(90deg);
      display: flex;
      font-size: 1rem;
      transition: 0.2s ease-in-out all;
      svg {
        path {
          stroke: var(--primary);
        }
      }
    }

    &[open] {
      &:hover {
        summary {
          background-color: var(--tertiary-contrast);
        }
        .details-content {
          background-color: var(--white);
        }
      }

      summary {
        border-bottom: 1px solid var(--light);
        background-color: var(--light-contrast);
      }

      .chevron {
        transform: rotate(270deg);
      }
    }

    .details-content {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__title {
      margin: 0;
    }

    .btn-link {
      color: var(--primary);
    }

    &__floor {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__floor-name,
    &__room-name {
      text-align: left;
      position: relative;
      margin: 0;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      hr {
        position: relative;
        margin-left: 1rem;
      }
    }

    &__room-name {
      margin-left: 5.5rem;

      @media (max-width: 640px) {
        margin-left: 0;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // @media (max-width: 640px) {
      //   flex-direction: column;
      //   align-items: flex-start;
      // }

      @media (max-width: 350px) {
        gap: 0.5rem;
      }

      // &--title {
      //   @media (max-width: 640px) {
      //     align-items: flex-start;
      //     flex-direction: column;
      //   }
      // }

      &--rooms {
        margin-left: 5.5rem;

        @media (max-width: 640px) {
          margin-left: 0;
        }
      }
    }

    &__status-wrapper {
      svg path {
        stroke: var(--danger);
      }

      @media (max-width: 640px) {
        margin-bottom: 2rem;
      }
    }

    &__item-count {
      padding: 0.2rem 1rem;
      margin: 0;
      border-radius: 16px;
      border: 1px solid var(--primary);
    }

    .bottom-toolbar {
      width: 100%;
      gap: 1rem 2rem;
      padding: 1.5rem;
      background-color: var(--light-contrast);
      border-top: 1px solid var(--light);

      .btn {
        flex-grow: 1;
      }
    }
  }
}
