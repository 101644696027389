.aui-root {
  .batch-select-small {
    justify-content: space-between;

    .batch-select-left {
      background-color: var(--primary);
      padding: 0 1rem 0 0.5rem;
      pointer-events: all;
      gap: 0;

      .btn-link {
        color: var(--white);
        font-size: 16px;
      }

      .item-counter {
        background-color: var(--white);
        border-radius: 20px;
        padding: 0 0.8rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--primary);
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .batch-select-left,
    .btn {
      border-radius: 100px;
    }

    .btn.aui-popup-menu-button {
      pointer-events: all;
      flex-direction: row-reverse;
      transition: all 0.2s ease-out;
      justify-content: end;

      .batch-action-text {
        max-width: 200px;
        transition: all 0.2s ease-out;
      }

      &.active {
        gap: 0;

        .batch-action-text {
          opacity: 0;
          max-width: 0;
        }
      }
    }
  }
}

#aui-popup-menu-container-body:has(.mobile-batch-select-options) {
  background-color: rgba(var(--dark-rgb), 0.3);
}

.aui-popup-menu-container {
  .aui-popup-menu.mobile-batch-select-options {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0 0 1rem;
    gap: 1rem;
    min-width: 0;

    .btn {
      border-radius: 100px;
      padding: 1.6rem;
      background-color: white;
    }
  }
}
