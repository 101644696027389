.pagetable-footer {
  display: flex;
  border-top: 1px solid var(--light);
  padding: 1rem 2rem;
  gap: 2rem;
  background-color: var(--white);

  .aui-form-control.aui-select {
    margin: 0;
    display: flex;
    align-items: center;
    width: initial;
    gap: 1rem;

    select {
      height: 40px;
      max-width: initial;
      min-width: initial;
      width: initial;
    }
  }

  @media (max-width: 550px) {
    justify-content: center;
    .aui-form-control.aui-select {
      display: none;
    }
  }
}
