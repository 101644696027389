#popup-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4990;

  background-color: rgba(var(--dark-rgb), 0.05);
  opacity: 0;
  pointer-events: none;
  transition: opacity 100ms ease-out;

  &:not(:empty) {
    opacity: 1;
    pointer-events: auto;
  }
}

.aui-root div.popup-menu {
  position: fixed;
  z-index: 5000;
  overflow: auto;
  background: var(--light-lighter);
  box-shadow: var(--shadow-md);
  min-width: 200px;
  padding: var(--spacing-1);
  gap: 5px;
  border-radius: var(--radius);
  max-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  animation: fadein 150ms ease-out;

  border: 1px solid var(--light);

  & > .btn,
  & > .btn-link {
    border: none;
    border-radius: var(--btn-radius);
    padding: 10px 15px;
    text-align: left;
    justify-content: flex-start;
    font-weight: var(--default-font-weight);
    gap: 10px;
    text-decoration: none;

    background: transparent;
    transition-property: color, background-color;

    color: var(--default-text);

    &.active {
      color: var(--primary);
      font-weight: var(--strong-font-weight);
    }

    &:hover {
      background: rgba(var(--primary-rgb), 0.05);
    }

    &.close-popup-menu {
      position: absolute;
      left: -9999px;

      &:focus-visible {
        position: relative;
        left: 0;
      }
    }

    &.close-fullscreen {
      align-self: flex-end;
      padding: 10px;
      margin: 10px;
    }

    &.close-fullscreen {
      position: fixed;
      z-index: 2;
    }
  }
}
