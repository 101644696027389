.aui-root .object-item-list {
  gap: 1.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  overflow: hidden;
  align-items: start;

  .no-image-placeholder {
    height: 60px;
  }

  .no-image-placeholder,
  .object-preview-image {
    border: 1px solid var(--light);
    border-radius: 8px;
    aspect-ratio: 1;
  }

  &:has(details[open]) .object-preview-image {
    background-color: var(--dark-contrast);
  }

  &:has(details[open]) .object-preview-image,
  &:has(details[open]) .no-image-placeholder {
    object-fit: contain;
    width: 50vw;
    height: 50vh;
    max-width: 800px;
    max-height: 800px;

    @media (max-width: 1240px) {
      width: 40vw;
      height: 30vh;
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
  }

  @media (min-width: 600px) {
    &:not(:has(details[open])) .object-preview-image {
      height: 60px;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: 0 1rem;

    &:has(img) {
      background-color: var(--light-contrast);
      border-radius: var(--radius);
      border: 1px solid var(--light);
      padding-top: none;
      gap: 0.5rem;

      details {
        box-shadow: none;
      }

      .object-preview-image {
        width: 100%;
        justify-self: center;
        height: 200px;
        object-fit: contain;
        background-color: var(--dark-contrast);
      }
    }

    .no-image-placeholder {
      display: none;
    }
  }

  &__img-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    &:hover {
      .object-item-list__img-wrapper__img-btn {
        opacity: 1;
        visibility: visible;
      }
    }

    &__img-btn {
      position: absolute;
      z-index: 10;
      top: calc(50% - 2rem);
      opacity: 0;
      visibility: hidden;
    }
  }
}
