.report-options .modal-content-container {
  width: 600px;
  padding: 2rem;
  color: var(--darkTextColor);

  form {
    padding-top: 2rem;
  }

  .modal-header {
    font-size: 24px;
  }

  .subheading {
    font-size: 16px;
    margin: 0rem 0 1.2rem 0;
    font-weight: 500;
  }

  .format-options {
    margin-bottom: 2rem;
    gap: 2rem;
    .aui-radio {
      font-size: 1.6rem;
    }
  }
}
