.aui-root {
  .table {
    border-collapse: collapse;
    border: none;
    width: 100%;
    table-layout: fixed;

    &--60 {
      width: 60%;

      @media (max-width: 640px) {
        padding-right: 2rem;
      }
    }

    &--40 {
      width: 40%;
    }

    &__body {
      height: 40rem;
    }

    &__header {
      background: var(--white);

      &__text {
        background: var(--white);
        border-top: 1px solid var(--light);
        border-bottom: 1px solid var(--light);
        font-weight: 500;
        font-size: 1.2rem;
        padding: 1.3rem 0;
        text-align: left;

        @media (max-width: 640px) {
          border-bottom: none;
          min-width: 150px;
        }

        &:first-of-type {
          padding-left: 2.4rem;

          @media (max-width: 640px) {
            width: 22.5rem;
          }
        }

        &:last-of-type {
          padding-right: 2.4rem;

          @media (max-width: 640px) {
            padding-left: 3.5rem;
          }
        }

        .checkbox {
          &__text {
            margin-bottom: 0;
            margin-left: 0.5rem;

            p {
              color: var(--default-text);
              font-size: 1.2rem;
              font-weight: 500;
            }
          }
        }
      }
    }
    &__pagination-wrapper {
      margin-top: 2rem;
      margin-left: 2rem;

      @media (max-width: 640px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
      }
    }
  }
}
