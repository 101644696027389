.aui-root {
  .feature-delete-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.6rem;

    p {
      margin: 0;
    }
    &__amount {
      color: var(--gray-900);

      &--red {
        color: var(--danger);
      }
    }

    &:last-of-type {
      margin: 0;
    }

    &__skeleton-text {
      position: relative;
      top: -0.35rem;
    }
  }
}
