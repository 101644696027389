.aui-root {
  .grid-item {
    border-radius: 12px;
    width: calc(20% - 0.8rem);
    border: 1px solid var(--light);
    box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
      0 4px 8px -2px rgba(16, 24, 40, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: 0.15s ease-in-out border;

    @media (min-width: 2200px) {
      width: calc(16.6% - 0.8rem);
    }

    @media (max-width: 1600px) {
      width: calc(25% - 0.8rem);
    }

    @media (max-width: 1300px) {
      width: calc(33.3% - 0.7rem);
    }

    @media (max-width: 640px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    &:hover {
      border-color: var(--primary);

      .grid-item__text {
        background: var(--tertiary-contrast);
      }
    }

    &__image {
      height: 17rem;
      width: 100%;
      position: relative;

      .no-image-placeholder {
        height: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      span,
      img {
        width: 100%;
      }

      &__condition {
        position: absolute;
        top: 0;
        right: 1rem;
        z-index: 1;
      }

      &__checkbox {
        position: absolute;
        z-index: 1;
        top: 0.8rem;
        left: 1rem;
      }
    }

    &__text {
      padding: 1.5rem;
      transition: 0.15s ease-in-out background;

      p {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}
