.aui-root {
  .profile-metadata-modal {
    background: var(--white);
    width: 60rem;
    border-radius: 16px;
    padding: 2rem;
    position: relative;

    &__profile-card-info {
      padding: 0px;
      overflow: hidden;
    }

    &__profile-card-image {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__close {
      position: absolute;
      right: 2rem;
      cursor: pointer;
    }

    .btn.btn-close {
      width: 100%;
    }

    &__title {
      font-size: 1.8rem;
      line-height: 2.8rem;
      text-align: left;
      font-weight: 600;
      margin: 0 0 0.4rem;
      color: var(--dark);
      &-container {
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--light);
        margin-bottom: 2rem;
      }
    }
    &__user__img {
      background: var(--primary);
      width: 10rem;
      height: 10rem;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-weight: 700;
      font-size: 3rem;
      margin-right: 3rem;
    }
    &__name {
      color: var(--warning-contrast);
      font-weight: 700;
      font-size: 2.4rem;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 2rem 0 2rem;
    }
    &__type {
      color: var(--dark-contrast);
    }
    &__value {
      color: var(--dark);
      font-weight: 600;
    }
    .separator {
      border-bottom: 1px solid var(--light);
      &__title {
        border-bottom: 10px solid var(--light);
        font-size: 1.8rem;
        line-height: 2.8rem;
        text-align: left;
        font-weight: 600;
        margin: 0 0 0.4rem;
        color: var(--dark);
      }
    }
  }
  .profile-metadata-modal__profile-card-info
    > .profile-metadata-modal__row:nth-of-type(even) {
    background-color: var(--light-contrast);
  }
}
