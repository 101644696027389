.aui-root {
  .feature-preview {
    display: flex;
    align-items: flex-start;
    gap: 2.4rem;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      padding: 0;
    }

    &__description-title {
      margin-top: 0;
    }

    &__left {
      display: flex;
      flex-direction: column;
      flex: 5;
      padding: 2.4rem;
      border-radius: 1.2rem;
      border: 1px solid var(--light);
      background: var(--white);

      /* Shadow/md */
      box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.1);

      @media (max-width: 1400px) {
        flex: 1;
      }

      @media (max-width: 1200px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 1rem;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      flex: 3;
      overflow: hidden;
      gap: 2.5rem;
      border-radius: 12px;

      @media (max-width: 1400px) {
        flex: 1;
        width: 100%;
      }

      .horizontaltabs-item {
        width: 100%;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      padding: 2.4rem;
      min-height: 13.5rem;
      margin-top: -0.8rem;
      border-radius: 12px;
      border: 1px solid var(--light);
      background: var(--white);
      overflow-wrap: break-word;
      margin-bottom: 2.4rem;

      .span {
        max-width: auto;
      }

      /* Shadow/md */
      box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    }

    &__table {
      display: flex;
      flex-direction: column;
      border-radius: 1.2rem;
      border: 1px solid var(--light);
      overflow: hidden;
    }

    &__table-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 2.4rem;
      min-height: 4.8rem;
      border-bottom: 1px solid var(--light);
      background: var(--light-contrast);
      gap: 2.4rem;
      text-align: right;
      overflow: hidden;
      overflow-wrap: break-word;

      &:nth-child(even) {
        background-color: var(--white);
      }

      &:first-of-type {
        border-radius: 1.2rem 1.2rem 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 1.2rem 1.2rem;
        border-bottom: none;
      }

      &__left {
        color: var(--dark-contrast);
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2rem;
      }

      &__right {
        color: var(--dark);
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2rem;
        overflow-wrap: break-word;
        overflow: hidden;

        .text {
          max-width: auto;
          overflow-wrap: break-word;
          overflow: hidden;
        }

        &.link {
          color: var(--primary);
          cursor: pointer;
          display: inline-block;
          position: relative;
          transition: 0.15s ease-in-out color;

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0.1rem;
            background: var(--btn-medium-hover-background);
            opacity: 0;
            transition: 0.15s ease-in-out opacity;
          }

          &:hover {
            color: var(--btn-medium-hover-background);
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }

    &__button-edit {
      display: flex;
      justify-content: center;
      max-width: 11rem;
      margin-bottom: 2.4rem;

      svg {
        margin-right: 1rem;
        vertical-align: -0.4rem;

        path {
          stroke: var(--white);
        }
      }
    }

    &__obj-condition-wrapper {
      display: flex;
      flex-direction: row;
      margin-top: -0.5rem;
      margin-bottom: 2.5rem;

      .object-status {
        &:not(:last-of-type) {
          margin-right: 2rem;
        }
      }
    }
  }
}
