.aui-root {
  .dropzone-attachments {
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: 5rem auto;
    gap: 1.2rem;

    .btn.light {
      min-width: initial;
      margin-bottom: 0;
    }

    .no-image-placeholder,
    .thumbnail-image {
      max-width: 5rem;
      max-height: 5rem;
      min-height: 5rem;
      border: 1px solid var(--light);
      border-radius: var(--radius);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__btn-amount {
      background: var(--tertiary-contrast);
      color: var(--primary);
      font-weight: 500;
      margin-left: 0.2rem;
      font-size: 1.2rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
    }
  }
  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    border-radius: 12px;
    border: 1px solid var(--light);
    background: var(--white);
    outline: none;
    transition: border 0.15s ease-in-out;
    margin-top: 0.5rem;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: var(--primary);
    }

    &--has-img {
      border: 1px solid transparent;
      padding: 0;
      flex-direction: row;
      justify-content: space-between;
      cursor: initial;
      background: var(--white);

      &:hover {
        border-color: transparent;
      }
    }

    &__input {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      opacity: 1;
      top: 0;
    }

    &__img {
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 8px;

      &__delete {
        right: 0.5rem;
        position: relative;
        cursor: pointer;

        svg {
          height: 2.4rem;

          path {
            fill: var(--danger);
            transition: 0.15s ease-in-out filter;
          }
        }

        &:hover {
          svg path {
            filter: brightness(80%);
          }
        }
      }
    }

    &__icon {
      position: relative;
      z-index: 1;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      margin: 1rem;
      display: inline-block;

      svg {
        height: 1.8rem;
        position: relative;
        left: 0.3rem;
        vertical-align: -1rem;
      }

      &:before {
        content: "";
        position: absolute;
        background: #eff1f5;
        width: 120%;
        height: 120%;
        top: 50%;
        left: 50%;
        transform: translate(-42%, -48%);
        border-radius: 50%;
        z-index: -1;
      }

      &:after {
        content: "";
        position: absolute;
        background: #f9f9fb;
        width: 170%;
        height: 170%;
        top: 50%;
        left: 50%;
        transform: translate(-44%, -48%);
        border-radius: 50%;
        z-index: -2;
      }
    }

    &__upload-text {
      color: var(--primary);
      font-weight: 600;
      margin: 2.5rem 0 0;
      font-size: 1.4rem;
      line-height: 2rem;

      span {
        color: var(--default-text);
        font-weight: 400;
      }
    }

    &__available-image {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}
