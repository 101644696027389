.popup-menu-button {
  &.btn-link {
    border-radius: var(--btn-radius);
    transition-property: color, background-color, text-decoration;
    background: transparent;

    &.active,
    &:hover {
      background: rgba(var(--primary-rgb), 0.05);
    }
  }
}
