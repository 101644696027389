.aui-root {
  .project-summary {
    border: 1px solid var(--light);
    border-radius: var(--radius);
    background: var(--white);
    padding: 1.6rem 3.6rem;
    gap: 3rem 6rem;

    @media (max-width: 800px) {
      margin: 0;
      padding: 1.6rem;
    }

    .read-more-btn {
      font-size: 1.4rem;
      padding: 1rem;
    }

    &__item {
      &--yellow {
        .text--size--medium {
          color: var(--warning);
        }
      }
      &--primary {
        .text--size--medium {
          color: var(--primary);
        }
      }
    }
  }
}
