.aui-root {
  .location-modal-floors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;

    background: var(--light-contrast);
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 2rem;
    gap: 2rem;
    overflow: hidden;

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;
      overflow: scroll;
      padding: 3rem 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__center-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: -2rem 0 1rem;
    }

    &__line {
      height: 0.2rem;
      background-color: var(--light);
    }

    &__text {
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-weight: 600;

      &__above {
        align-self: flex-start;
        margin-bottom: 1rem;
      }

      &__under {
        align-self: flex-end;
        margin-top: 1rem;
      }

      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
    }

    &__button-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &__up {
        margin-bottom: 3rem;
        &:before {
          top: -0.9rem;
        }
        &:after {
          top: -2.5rem;
        }
      }

      &__down {
        &:before {
          top: 4.4rem;
        }

        &:after {
          top: 5.3rem;
          transform: rotate(-180deg);
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: calc(50% - 0.2rem);
        width: 0.4rem;
        height: 0.5rem;
        background-color: var(--dark-contrast);
      }

      &::after {
        content: "";
        position: absolute;
        left: calc(50% - 0.6rem);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.6rem 1.2rem 0.6rem;
        border-color: transparent transparent var(--dark-contrast);
      }

      .btn {
        &.small {
          .aui-icon {
            font-size: 1.6rem;
          }
          .text {
            margin: 0 0 0.1rem;
          }
        }
        &::before {
          content: "";
          position: absolute;
          left: calc(50% - 0.2rem);
          bottom: -2rem;
          height: 1.4rem;
          border: 0.2rem solid var(--dark-contrast);
          border-style: dashed;
        }
      }

      &__down .btn::before {
        top: -2rem;
      }
    }
  }
}
