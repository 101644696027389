.sidebar {
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 28rem;
  min-height: 100%;
  position: fixed;
  box-shadow: 9px 2px 27px -36px rgba(0, 0, 0, 0.8);
  transition: 0.15s ease-in-out width;
  z-index: 21;

  .sidebar__link-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 1024px) {
    transition: 0.15s ease-in-out height, min-height;
    width: 100%;
    height: 8rem;
    min-height: auto;
    overflow: hidden;
  }

  @media (max-height: 860px) and (min-width: 1024px) {
    min-height: 100%;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--collapse {
    width: 9.2rem;

    @media (max-width: 1440px) {
      .sidebar__top__collapse-button {
        margin-top: 1.2rem;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      height: 100svh;
      min-height: 100svh;
      overflow: scroll;
      z-index: 21;

      .sidebar__top__collapse-button {
        margin-top: -1rem;
      }
    }

    @media (min-width: 1024px) {
      .sidebar__top,
      .sidebar__bottom {
        justify-content: center;
        margin-left: 0;

        &__collapse-button {
          transform: rotate(180deg);
        }
      }

      .sidebar__center {
        padding: 2rem 1rem;
        margin-left: 0;

        p {
          text-align: center;
          padding-left: 0;
        }
      }

      .sidebar__bottom-links {
        margin-left: 0;

        p {
          text-align: center;
          padding-left: 0;
        }
      }

      .sidebar__add-button {
        padding: 1.1rem;
        margin-bottom: 1.2rem;

        .btn {
          width: 75%;
          margin: 0 auto;
          padding: 1.4rem;
        }
      }
    }
  }

  &__top {
    padding: 1.2rem 2rem 3.6rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 11.5rem;
    margin-left: -1.5rem;
    background: var(--white);

    @media (max-width: 1440px) {
      padding: 2rem 2rem 1rem;
      min-height: auto;
    }

    @media (max-width: 1024px) {
      padding: 2rem 2rem 1rem;
      margin-left: 0;
    }

    &__logo {
      height: 4.5rem;
      position: relative;

      @media (max-width: 1024px) {
        left: auto;
        order: 1;
        right: 9rem;
      }

      svg {
        height: 4.5rem;
      }
    }

    &__collapse-button {
      cursor: pointer;
      transition: 0.15s ease-in-out background;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1024px) {
        margin-top: -1rem;
      }

      path {
        transition: 0.15s ease-in-out fill;
        fill: var(--primary);
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: transparent;
        padding: 0.5rem;
        border: 1px solid transparent;
        transition: 0.15s ease-in-out all;

        &:hover {
          border-color: var(--primary);
          background: var(--tertiary-contrast);
        }
      }
    }

    &__close-icon {
      path {
        @media (max-width: 1024px) {
          stroke: var(--primary);
        }
      }
    }
  }

  &__link-title {
    color: var(--input-text-color);
    padding-left: 2rem;

    @media (max-width: 1024px) {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0.8rem;
    }
  }

  &__center {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    padding: 2rem;
    margin-left: -1.5rem;
    background: var(--white);

    @media (max-width: 1440px) {
      padding: 0 2rem;
    }

    @media (max-width: 1440px) and (max-height: 800px) {
      padding: 0 1.5rem;
    }

    @media (max-width: 1024px) {
      padding: 0 3rem;
      margin-left: 0;
    }
  }

  &__add-button {
    padding: 1rem 2rem 2rem;
    background: var(--white);

    @media (max-width: 1440px) {
      padding: 2rem;
    }

    @media (max-width: 1024px) {
      padding: 1rem 2rem 2rem;
    }

    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 50px;
      padding: 1.4rem 2rem;

      &:hover {
        color: var(--white);
      }

      p {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  &__bottom-links {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem;
    margin-left: -1.5rem;
    background: var(--white);

    @media (max-width: 1440px) {
      padding: 0 2rem;
    }

    @media (max-width: 1024px) {
      padding: 0 3rem;
      margin-left: 0;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-top: 1px solid var(--light);
    transition: 0.15s ease-in-out all;
    cursor: pointer;
    background: var(--white);

    @media (max-width: 1440px) {
      padding: 1.5rem 2rem;
    }

    @media (max-width: 1200px) {
      padding: 2rem;
    }

    @media (max-width: 1024px) {
      padding: 2rem 2.5rem;
    }

    &:hover {
      background: var(--tertiary-contrast);
      border-top-color: var(--primary);

      svg path {
        fill: var(--primary);
      }

      span {
        color: var(--primary);
      }
    }

    &--active {
      background: var(--primary);

      &:hover {
        background: var(--btn-medium-hover-background);
      }

      .sidebar__user {
        &__img {
          &--default {
            background: var(--white);
            color: var(--primary);
          }
        }

        p,
        span {
          color: var(--white);
        }
      }

      path {
        fill: var(--white);
      }
    }
  }

  &__user {
    flex: 1;
    margin-left: 1.5rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 0.4rem;

    &__img {
      width: 4rem;
      height: 4rem;
      border-radius: 50px;

      &--default {
        background: var(--primary);
        width: 4rem;
        height: 4rem;
        border-radius: 50px;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: 700;
        font-size: 1.4rem;
      }
    }

    &__name,
    p {
      font-weight: 600;
      color: var(--primary);
      margin: 0 0 -0.2rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &__email,
    span {
      color: var(--default-text-color);
      font-size: 1rem;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 95%;
      display: inline-block;
      transition: 0.15s ease-in-out color;
    }
  }
}
