.page-container {
  display: flex;
  flex: 1;
  background: #fcfcfd;
  overflow: auto;

  &__content {
    flex: 1;
    padding: 2.4rem 2rem 1.2rem 30rem;
    width: calc(100% - 28rem);
    transition: 0.15s ease-in-out padding;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 8rem 2rem 2rem 2rem;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 8rem 1.2rem 0;
    }

    &--collapse {
      padding: 2rem 2rem 2rem 11.2rem;
      width: calc(100% - 9.2rem);

      @media (max-width: 1024px) {
        padding: 8rem 2rem 2rem 2rem;
      }
    }

    &--no-sidebar {
      padding: 2rem 2rem 2rem 11.2rem;
      width: calc(100% - 9.2rem);

      @media (max-width: 1024px) {
        padding: 0 2rem 2rem 2rem;
      }
    }
  }
}
