.filter-modal {
  .feature-modal-wrapper {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    padding: 0;
    max-height: 80vh;
    background-color: white;

    @media (max-width: 1000px) {
      max-height: 100vh;
    }

    .aui-form-control,
    .custom-input-select {
      margin-bottom: 0;
    }

    .subsection {
      margin-top: 1.2rem;
    }

    .checkbox-container-outer {
      display: flex;
      flex-direction: row;
      gap: 1.2rem;
      width: 100%;

      @media (max-width: 700px) {
        flex-direction: column;
      }

      .checkbox-container {
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
        width: 100%;
        .aui-check-box {
          width: fit-content;
          padding: 0;
          margin: 0;
          height: 100%;
          display: flex;
        }
        .container {
          border-radius: 16px;

          p {
            margin: 0.4rem 1.2rem;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.8rem;
          }
        }
      }
    }

    h4 {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.8rem;
      margin: 0;
      padding-bottom: 1.2rem;
    }

    button.btn.purple {
      color: var(--primary);
      background-color: var(--light-contrast);
      border: none;
    }
    button.btn.purple:hover {
      background-color: var(--tertiary-contrast);
    }

    button.btn.purple:active {
      background-color: #d9daf2 !important;
    }

    .wrapper-content {
      background-color: var(--light-contrast);
    }

    .wrapper-content,
    .modal-header {
      padding: 2.4rem;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      padding: 1.2rem;
      overflow-y: auto;
    }

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1.2rem;

      p {
        margin: 0;
        padding-bottom: 6px;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    .date-container {
      display: flex;
      flex-direction: row;
      gap: 2.4rem;

      @media (max-width: 480px) {
        flex-direction: column;
      }
    }

    .filter-modal-buttons {
      gap: 2.4rem;
      padding: 2.4rem;
      border-top: 1px solid var(--light);
      background-color: var(--light-contrast);
      .submit-buttons {
        margin-top: 0;
      }
      border-radius: 0 0 16px 16px;
    }
  }
}
