.aui-root {
  .table-row {
    background: var(--light-contrast);
    border-bottom: 1px solid var(--light);

    @media (max-width: 640px) {
      display: inherit;
    }

    &:nth-of-type(1) {
      @media (max-width: 640px) {
        border-top: 1px solid var(--light);
      }
    }

    &:nth-child(even) {
      background: var(--white);
    }

    &__cell {
      padding: 1.6rem 0;
      font-size: 1.4rem;

      p {
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--dark-contrast);
        min-width: 5.5rem;

        @media (max-width: 640px) {
          width: 110px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:first-of-type {
        padding-left: 2.4rem;
      }

      &:last-of-type {
        padding-right: 2.4rem;

        @media (max-width: 640px) {
          padding-left: 2rem;
        }
      }
    }

    &--checkbox-skeleton {
      margin-right: 1.2rem;
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-select {
        min-width: 9rem;
      }
    }

    &__name {
      .checkmark {
        margin-right: 1.2rem;
      }

      .hidden {
        visibility: hidden;
      }

      &__text {
        margin-left: 1.2rem;

        p {
          margin: 0;

          &:first-of-type {
            color: var(--dark);
            font-weight: 500;
          }
        }
      }
    }

    &__share-icon {
      width: 1.6rem;
      height: 1.6rem;

      svg {
        height: 1.6rem;
        vertical-align: 0;
      }
    }
    .profile-metadata-btn-container {
      cursor: pointer;
      height: 1.6rem;

      svg path {
        transition: 0.15s ease-in-out fill;
      }

      &:hover {
        svg path {
          fill: var(--primary);
        }
      }
    }
  }
  .profile-delete-btn-container {
    cursor: pointer;
    width: 2rem;

    svg path {
      transition: 0.15s ease-in-out fill;
    }

    &:hover {
      svg path {
        fill: var(--danger);
      }
    }
  }
}
