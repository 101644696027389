.select-location-for-product .modal-content-container {
  max-width: 600px;
  padding: 2rem;

  form {
    padding-top: 2rem;
  }
}

.product-editor-modal {
  height: 100%;
  overflow: hidden;
  width: 850px;
  max-width: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  padding: 2rem;

  @media (min-width: 850px) {
    max-height: 90svh;
  }

  .form-and-links-container {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 2rem 0 0;
    gap: 2rem;

    @media (max-width: 850px) {
      gap: 0;
      .form-links {
        display: none;
      }
    }
  }

  form.product-editor-form {
    height: 100%;
    overflow: auto;
    display: grid;
    gap: 1.2rem;

    @media (max-width: 850px) {
      overflow: hidden;
    }
  }

  .form-links {
    padding: 2rem;
    border: 1px solid var(--light);
    border-radius: 1.2rem;
    background-color: var(--light-contrast);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
  }

  .error-message-output {
    color: var(--danger);
    text-align: center;
  }
}

.buttom-buttons .btn {
  flex: 1 1 0;
}

.modal-content-container {
  background-color: var(--white);
  border-radius: var(--radius);
  overflow: hidden;
  margin: 1rem;

  &.success-modal {
    max-height: 90%;
    padding: 2rem;
    overflow-y: auto;

    .modal-header {
      border: none;
    }
  }
}
