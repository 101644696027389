.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: grid;
  place-items: center;

  font-size: 1.5em;

  @keyframes dots-anim {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 0.2;
      transform: none;
    }
    40% {
      opacity: 1;
      transform: translate(0, -0.1em) scale(1.1);
    }
    60% {
      opacity: 0.2;
      transform: none;
    }
    100% {
      opacity: 0.2;
    }
  }

  .label {
    color: var(--primary);
  }

  .loading-spinner {
    display: inline-block;

    span {
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      background: var(--primary);
      border-radius: 0.5em;
      margin: 0.1em;
      animation: dots-anim 1.5s ease-in-out infinite;
      opacity: 0.2;
    }

    span:nth-child(2) {
      animation-delay: 0.2s;
    }
    span:nth-child(3) {
      animation-delay: 0.4s;
    }
    span:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
}
