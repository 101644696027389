.aui-root {
  .feature-image-preview {
    &--no-image {
      height: 36.9rem;

      @media (max-width: 1640px) {
        height: 30rem;
      }

      @media (max-width: 1200px) {
        height: 39rem;
      }
      @media (max-width: 640px) {
        height: 30rem;
      }
    }
  }
}
