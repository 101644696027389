.aui-root {
  .upload-image-placeholder-wrapper {
    display: flex;
    flex: 1;
  }

  .upload-image-placeholder {
    max-width: 50rem;
    background: var(--success-contrast);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 4rem auto;
    background: var(--white);
    align-self: center;

    &--grid {
      height: auto;
      padding: 4rem;
      border: 1px solid var(--light);
      border-radius: 8px;
      margin: 8rem auto;
    }

    @media (max-width: 1024px) {
      height: auto;
      margin: 2rem auto;
      padding: 3rem 2rem;
    }

    @media (max-width: 768px) {
      margin: 2rem 0;
    }

    .aui-icon {
      width: 5.5rem;
      height: 5.5rem;
    }

    &__icon {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      svg {
        width: 2.3rem;
        height: 2.3rem;

        path {
          fill: var(--primary);
        }
      }

      &:before {
        content: "";
        position: absolute;
        background: var(--link-text);
        width: 85%;
        height: 85%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: -1;

        @media (max-width: 768px) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }

      &:after {
        content: "";
        position: absolute;
        background: var(--tertiary-contrast);
        width: 125%;
        height: 125%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: -2;

        @media (max-width: 768px) {
          width: 6.5rem;
          height: 6.5rem;
        }
      }
    }

    &__title {
      margin: 1rem 0;
    }

    &__description {
      margin: 0 0 2rem;
      text-align: center;
      max-width: 80%;
    }

    button {
      svg {
        vertical-align: -0.2rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
}
