.aui-root {
  .attachment-modal {
    // z-index: 300;
    .attachment-modal-container {
      width: 111rem;
      background: var(--default-background);
      padding: 2rem 2rem 0;
      text-align: center;
      border-radius: var(--radius);
      overflow: hidden;
      background: var(--white);

      @media (max-width: 1240px) {
        margin: 0 2rem;
      }

      @media (max-width: 1024px) {
        width: 100%;
        height: 95svh;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media (max-height: 850px) and (min-width: 1025px) {
        overflow: auto;
        max-height: 95%;
      }

      @media (max-width: 640px) {
        min-width: auto;
      }

      form div.attachment-input-fields {
        .aui-check-box.disabled,
        .aui-check-box.disabled label,
        .aui-check-box.disabled input {
          cursor: not-allowed;
        }
      }

      form div.attachment-input-fields:not(.active) {
        display: none;
      }

      .button-row {
        margin: 1rem 0 2rem;
        gap: 2rem;

        .btn {
          width: 100%;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid var(--light);
      padding-top: 2rem;
      text-align: left;
      gap: 2rem;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
      }

      &__inputs {
        width: 60%;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      &__slider {
        width: 40%;

        position: relative;
        background-color: var(--dark-contrast);
        border: 1px solid var(--light);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        overflow: hidden;

        @media (max-width: 1024px) {
          width: 100%;
          margin-left: 0;
        }

        .no-image-placeholder,
        img {
          height: 33rem;
        }

        .no-image-placeholder {
          width: 100%;
        }
      }
    }

    .delete-attachment-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
      padding: 0.6rem;
    }

    .use-as-thumbnail {
      padding: 0;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--label-text);

      .aui-icon {
        color: var(--input-border-color);
      }

      .aui-icon.active {
        color: var(--primary);
      }

      &.active {
        cursor: not-allowed;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 2rem;
      min-height: 18rem;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      &:has(:only-child) {
        justify-content: center;
        padding-bottom: 2rem;
      }

      &__dropzone {
        width: 25%;

        &:only-child {
          width: 50%;
          justify-self: center;
        }

        @media (max-width: 1024px) {
          width: 100%;
        }

        & > div {
          height: 100%;
          .container {
            height: 100%;
            .dropzone {
              max-width: 26.2rem;
              margin-top: 0;
              height: 100%;
              max-width: initial;
            }
          }
        }
      }

      &__slider-row {
        width: 75%;
        border: 1px solid var(--light);
        border-radius: 8px;
        margin-left: 2rem;
        position: relative;

        @media (max-width: 1024px) {
          display: none;
        }

        .attachment-modal__arrow {
          top: 40%;
        }
      }
    }

    &__arrow {
      position: absolute;
      top: 50%;

      &--next {
        right: 2rem;

        @media (max-width: 1024px) {
          right: 1rem;
        }
      }

      &--prev {
        left: 2rem;

        @media (max-width: 1024px) {
          left: 1rem;
        }
      }
    }

    .swiper-slide:has(.deleted) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        display: block;
        background-color: var(--danger);
        opacity: 0.5;
        inset: 0;
        border-radius: 12px;
        width: 12rem;
        height: 12rem;
      }
    }

    &__bottom-swiper {
      position: relative;
      margin: 0 -2rem;

      img,
      .no-image-placeholder {
        border-radius: 12px;
        width: 12rem;
        height: 12rem;

        &.active {
          border: 4px solid var(--primary);
        }
      }

      .no-image-placeholder {
        border: 2px solid var(--light);
      }

      .swiper {
        width: 80%;
        margin: 1.2rem auto -1.2rem;

        &--left-shadow {
          &:before {
            content: "";
            width: 4rem;
            height: 100%;
            background: linear-gradient(
              to left,
              rgba(255, 255, 255, 0) 0,
              var(--white) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;

            @media (max-width: 1024px) {
              display: none;
            }
          }
        }

        &:after {
          content: "";
          width: 4rem;
          height: 100%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0,
            var(--white) 100%
          );
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        .swiper-wrapper {
          margin-left: -25rem;

          @media (max-width: 1140px) {
            margin-left: -22rem;
          }

          @media (max-width: 1024px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .swiper-pagination-bullet {
    background: #eff1f5;
    opacity: 1;
    width: 1rem;
    height: 1rem;
    margin-right: 3rem;
  }

  .swiper-pagination-bullet-active {
    background: var(--primary);
    width: 1rem;
    height: 1rem;
  }
}
