.aui-root {
  .tag {
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eff1f5;
    padding: 0.2rem 1rem;
    cursor: pointer;
    transition: 0.2s ease all;

    &:hover {
      background: var(--input-text);
      color: var(--white);
    }

    &__text {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      margin: 0;
    }
  }
}
