.modal-header {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--light);
  width: 100%;

  &--no-border {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.modal-header-icons {
  display: flex;
  justify-content: space-between;
}

.modal-header-text {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
}

.add-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 4.8rem;
  height: 4.8rem;
  border: 1px solid #dcdfea;
  border-radius: 10px;
  cursor: default !important;
}

.close-icon {
  color: var(--inputTextColor);
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  margin: 0 0 0 auto;
  border: none;
  background-color: transparent;
  position: relative;
  top: 0.2rem;
}

.modal-header-text-main {
  text-align: left;
  margin-bottom: 1rem;
}

.modal-header-text-secondary {
  text-align: left;
  color: var(--dark-contrast);
}
