.terms-and-privacy-page {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  color: var(--dark);

  h1 {
    color: var(--dark);
    margin: 1rem 0;
  }

  .content-container {
    max-width: 110rem;
    margin: auto;
    padding: 2rem;

    .site-label {
      color: var(--primary);
      margin: 0;
    }

    article {
      font-size: 1.6rem;

      h2 {
        color: var(--primary);
        font-size: 2.4rem;
      }
      h3 {
        color: var(--primary);
        font-size: 1.6rem;
      }

      table {
        border: 1px solid var(--primary);
        border-radius: 5px;
        border-spacing: 0;

        th {
          padding: 0.2rem;
          background-color: var(--light-contrast);
          border-radius: 5px;
        }

        td {
          padding: 0.2rem;
        }
      }
    }
  }
}
