.feature-modal-wrapper {
  background-color: var(--light-contrast);
  width: 100%;
  border-radius: 1.2rem;
  border: 1px solid var(--light);
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding: 1.2rem;
  }
}
