.register-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  background: var(--white);
  min-height: 690px;
  @media (max-width: 1024px) {
    overflow: scroll;
    min-height: auto;
  }
}
