.pageheader-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 0;
  }

  .breadcrumb {
    text-decoration: none;
    color: var(--dark-contrast);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;

    &__last-item {
      border: none;
      border-radius: 6px;
      background: var(--tertiary-contrast);
      color: var(--primary);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem;
      padding: 0.2rem 0.8rem;
    }
  }
}
