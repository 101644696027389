.aui-root {
  .total-score {
    height: 7.2rem;
    width: 7.2rem;
    border-radius: 50%;
    background: #eff1f5;
    border: 1px solid var(--dark-contrast);
    color: var(--dark-contrast);
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    p {
      align-self: center;
    }

    &--small {
      border-radius: 2.4rem;
      margin: 0.5rem 0;
      height: 5rem;
    }

    &--bad {
      border-color: #d92d20;
      color: #d92d20;
      background: #fee4e2;
    }

    &--medium {
      border-color: #dc6803;
      color: #dc6803;
      background: #fef0c7;
    }

    &--high {
      border-color: #039855;
      color: #039855;
      background: var(--success);
    }
  }
}

//#D1FADF
//#039855
//
//
//#FEF0C7
//#DC6803
//
//
//#EFF1F5
//#4A5578
