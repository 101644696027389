.locations-sidebar-wrapper {
  position: fixed;
  top: 2rem;
  bottom: 2rem;
  right: -45rem;
  width: 44rem;
  transition: 0.2s ease-in-out right;
  z-index: 1001;

  &--visible {
    right: 2rem;
    visibility: visible;
  }

  @media (max-width: 1640px) {
    width: 42.5rem;
  }

  @media (max-width: 1024px) {
    top: 10rem;
    width: 34rem;
  }

  @media (max-width: 385px) {
    width: 25rem;
  }
}

.locations-sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .upload-image-placeholder {
    margin: 0;
    height: 38rem;

    @media (max-width: 1440px) {
      height: 23rem;
    }

    @media (max-width: 1024px) {
      height: 20rem;
    }
  }

  img {
    background: var(--gray-50, #f8fafc);
  }

  .slider-preview {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.locations-sidebar-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--white);
  padding: 0;
  margin: 0;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 21;
}

.locations-sidebar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  background-color: var(--white);
  padding: 0;
  margin: 0;
  width: 4.8rem;
  height: 4.8rem;
  border: none;
  position: absolute;
  top: 20rem;

  &--left {
    left: 1rem;
  }

  &--right {
    right: 1rem;
  }

  @media (max-width: 1440px) {
    top: 14rem;
  }
}

.locations-sidebar-img {
  border-radius: 1.2rem 1.2rem 0 0;
  height: 38rem;
  width: 100%;

  @media (max-width: 1440px) {
    height: 23rem;
  }

  @media (max-width: 1024px) {
    height: 20rem;
  }
}

.locations-sidebar-info-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background: var(--gray-50, #f8fafc);
  border-radius: 0 0 1.2rem 1.2rem;
  position: relative;
  flex: 1;
}

.locations-sidebar-info-container {
  padding: 1.6rem;
  border-radius: 12px;
  border: 1px solid var(--light);
  background: var(--white);
  margin-bottom: 8rem;
}

.locations-sidebar-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  height: 2.4rem;

  .locations-sidebar-info-button {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    width: 2.4rem;
    height: 2.4rem;

    svg {
      transition: transform 0.15s ease-in-out;
      transform: rotate(90deg) scale(1.5);
    }

    svg path {
      stroke: var(--primary);
    }
  }

  .locations-sidebar-info-button--visible {
    svg {
      transform: rotate(270deg) scale(1.5);
    }
  }
}
.locations-sidebar-info-items {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  border-radius: 0 0 1.2rem 1.2rem;
  margin-top: 1.6rem;
}

.locations-sidebar-info-item {
  display: flex;
  flex-direction: column;

  .locations-sidebar-info-item-title {
    color: var(--gray-900, #111322);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .locations-sidebar-info-item-text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--dark-contrast);
  }
}

.locations-sidebar-settings {
  display: flex;
  border-radius: 1.2rem;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
  position: absolute;
  bottom: 1rem;
  left: 1.2rem;
  right: 1.2rem;
  overflow: hidden;

  button {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--white);
    border: none;
    background: var(--btn-medium-background);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    transition: 0.15s ease background;
    width: 50%;

    @media (max-width: 1024px) {
      padding: 0.8rem;
    }

    svg path {
      stroke: var(--white);
    }

    &:hover {
      background: var(--btn-medium-hover-background);
    }
  }
}
