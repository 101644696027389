.register-bottom {
  display: flex;
  align-items: center;
}

.register-language {
  @media (max-width: 640px) and (max-height: 750px) {
    position: relative;
    top: -1rem;
    right: 0;
    order: -1;
    margin: 2rem 0;
    align-self: flex-end;
  }
}

.terms-checkbox {
  span.required {
    display: none;
  }
}

.form__privacy {
  .aui-check-box.terms-checkbox {
    a {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
