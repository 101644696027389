.batch-select-bar {
  width: fit-content;
  margin: auto;
  pointer-events: all;
  background-color: var(--primary);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  color: var(--white);
  border-radius: 50px;
  padding: 0.5rem 1em;
  width: 600px;
  max-width: 100%;
  justify-content: space-between;

  .btn {
    font-weight: 400;
  }

  .btn-link.icon {
    color: var(--white);
    font-size: 16px;
  }

  .item-counter {
    border-radius: 20px;
    padding: 0 0.8rem;
    text-align: center;
    font-size: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
