.aui-root {
  .table-sort-button {
    &__text {
      order: -1;
    }

    .sort-arrow {
      font-size: smaller;
      opacity: 0;

      &--visible {
        opacity: 1;
      }
    }
  }
}
