.location-modal-drop--aside {
  .dropzone-label {
    padding: 0 0 1.6rem 0;
    border-bottom: 1px solid var(--light);
  }
}

.feature-modal-aside {
  flex: 1;
}

.location-modal-aside {
  display: flex;
  width: 45%;
  height: auto;
  overflow: initial;

  @media (max-width: 1340px) {
    height: 55vh;
    overflow: scroll;
  }

  @media (max-width: 768px) {
    height: auto;
    overflow: initial;
  }
}

.location-modal-aside-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  justify-content: space-between;

  @media (max-width: 1340px) {
    gap: 1.2rem;
    min-height: 30vh;
  }
}

.location-modal-aside-steps {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  @media (max-width: 1024px) {
    display: none;
  }
}
