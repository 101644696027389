.password-input {
  .field-wrapper {
    position: relative;
  }

  .toggle-visible-button {
    position: absolute;
    top: 50%;
    margin: 0;
    right: 0;
    min-width: initial;
    transform: translateY(-50%);
  }

  &.error {
    input[type="text"],
    input[type="password"] {
      border-color: var(--danger);
    }
  }
}
