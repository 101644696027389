.aui-root {
  .profile-modal {
    background: var(--white);
    width: 40rem;
    border-radius: 16px;
    padding: 2rem;
    position: relative;

    @media (max-width: 640px) {
      width: 100%;
      margin: 0 2rem;
    }

    &--delete {
      .profile-modal {
        &__center-icon {
          margin: 1.5rem 1rem 3rem;

          svg {
            height: 2.2rem;
            path {
              fill: var(--danger);
            }
          }

          &:before {
            background: #fee4e2;
          }

          &:after {
            background: #fef3f2;
          }
        }
        &__title {
          text-align: left;
        }
      }
    }

    &--notification {
      width: 67rem;
    }

    &__close {
      position: absolute;
      right: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
    }

    &__close:hover {
      background-color: var(--tertiary-contrast);
    }

    &__close:active {
      background-color: var(--tertiary);
    }

    &__center-icon {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.5rem auto 2.5rem;
      height: 3rem;
      width: 3rem;
      left: -0.2rem;
      color: var(--primary);

      svg {
        height: 1.8rem;
        position: relative;
        left: 0.3rem;
      }

      &:before {
        content: "";
        position: absolute;
        background: var(--link-text);
        width: 140%;
        height: 140%;
        top: 50%;
        left: 50%;
        transform: translate(-42%, -48%);
        border-radius: 50%;
        z-index: -1;
      }

      &:after {
        content: "";
        position: absolute;
        background: var(--tertiary-contrast);
        width: 200%;
        height: 200%;
        top: 50%;
        left: 50%;
        transform: translate(-44%, -48%);
        border-radius: 50%;
        z-index: -2;
      }
    }

    &__title {
      font-size: 1.8rem;
      line-height: 2.8rem;
      text-align: center;
      font-weight: 600;
      margin: 0 0 0.4rem;
    }
    &__description {
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
      margin: 0 0 2rem;
    }

    .form {
      .aui-form-control,
      input {
        margin-bottom: 1.6rem;
      }
    }

    &__password-strength {
      margin-bottom: 1rem;

      div {
        :nth-of-type(2n - 1) {
          height: 0.4rem !important;
          border-radius: 8px;
        }
      }

      p {
        display: none;
      }
    }

    &__error {
      margin-bottom: -2.2rem;
    }

    .submit-buttons {
      margin-top: 1rem;
    }
    .aui-text {
      input {
        margin-bottom: 0;
      }
    }
  }
  .profile-summary__email-modal {
    .submit-buttons {
      margin-top: 3rem;
    }
  }
  .input-phone {
    margin-bottom: 1.6rem;
  }
}
