.reset-password {
  position: relative;

  &__result {
    text-align: center;
    h4 {
      font-size: 2.4rem;
      color: var(--dark);
      font-weight: 700;
      margin: 0;

      @media (max-width: 640px) {
        font-size: 2rem;
      }
    }
  }

  &__cancel {
    margin: 3rem auto 0;
  }

  &__back {
    position: absolute;
    top: 3rem;
    left: 3rem;

    @media (max-width: 640px) {
      top: 2rem;
      left: 2rem;
    }
  }
}
