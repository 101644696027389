.aui-root {
  .organisation-table {
    &__heading {
      margin: -2.4rem -2.4rem 0;

      .heading {
        padding: 1.4rem 2.4rem 2.4rem;

        @media (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__text {
          @media (max-width: 640px) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    &__add-user {
      display: flex;
      padding: 1rem 1.6rem;

      p {
        margin: 0;
      }
    }

    &__upgrade-plan {
      p {
        color: var(--warning);
        margin: 0;
      }

      svg {
        color: var(--warning);
      }

      border-color: var(--warning);
      background-color: var(--base-white);

      &:hover {
        background-color: #fffaeb;
      }
    }

    &__table {
      margin: -2.4rem -2.4rem 0;

      @media (max-width: 640px) {
        overflow-x: scroll;
      }

      .table-footer {
        margin: 1rem 2.4rem 0;
      }
    }
    .table__body {
      height: fit-content;
    }
  }

  .organisation-select {
    margin-bottom: 1.2rem;

    & > div {
      border-radius: 8px;
      border-color: var(--light);
      box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
        0 2px 4px -2px rgba(16, 24, 40, 0.06);
    }
    .table-select {
      width: 100%;
      font-size: 1.5rem;
      border-radius: 1rem;
      box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
        0 2px 4px -2px rgba(16, 24, 40, 0.06);
      border-color: var(--light);
    }
    .table-select:focus {
      border-color: var(--light);
    }
  }
}
