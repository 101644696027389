.feature-modal-container {
  min-height: 1% !important;

  .form-and-links-container {
    display: grid;
    grid-template-columns: 1fr auto;
    overflow: hidden;
    gap: 2rem;

    form.location-editor-form {
      flex-direction: column;
      gap: 1.2rem;
      overflow-y: auto;
    }

    .form-links {
      padding: 2rem;
      border: 1px solid var(--light);
      border-radius: 1.2rem;
      background-color: var(--light-contrast);
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow: auto;
    }

    @media (max-width: 850px) {
      gap: 0;
      .form-links {
        display: none;
      }

      form.location-editor-form {
        overflow: hidden;
      }
    }
  }
}
