.aui-root {
  .text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: var(--default-text-color);
    display: inline-block;

    &--bold {
      &--500 {
        font-weight: 500;
      }
      &--600 {
        font-weight: 600;
      }
      &--700 {
        font-weight: 700;
      }
      &--800 {
        font-weight: 800;
      }
    }

    &--size {
      &--small {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      &--medium {
        font-size: 2rem;
        line-height: 2.8rem;
      }
      &--big {
        font-size: 2.8rem;
        line-height: 3.8rem;
      }
      &--xs {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
      &--sm {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      &--md {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
      &--lg {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
      &--xl {
        font-size: 2rem;
        line-height: 3rem;
      }
    }

    &--color {
      &--dark {
        color: var(--dark);
      }
      &--dark-contrast {
        color: var(--dark-contrast);
      }
      &--light {
        color: var(--light);
      }
      &--light-contrast {
        color: var(--light-contrast);
      }
      &--primary {
        color: var(--primary);
      }
      &--primary-contrast {
        color: var(--primary-contrast);
      }
    }
  }
}
