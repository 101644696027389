.aui-root {
  .register-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 380px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      margin-top: 1rem;
    }

    @media (max-width: 1024px) {
      max-width: 340px;
    }

    h3 {
      font-weight: 600;
      line-height: 3.8rem;
      color: var(--dark);

      @media (max-width: 1400px) {
        margin: 1rem 0;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      text-align: center;

      &--desc {
        h3 {
          margin: 2rem 0 0;

          @media (max-width: 1440px) {
            margin: 1rem 0 0;
          }
        }

        p {
          margin-top: 1rem;
          margin-bottom: 3rem;

          @media (max-width: 1440px) {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .form {
    .aui-form-control {
      @media (max-width: 1440px) {
        margin-bottom: 0.8rem;
      }
    }
  }

  .btn-form {
    @media (max-width: 1440px) {
      margin-top: 2rem;
    }
  }
}
