.profile-card {
  background: var(--white);
  border: 1px solid var(--light);
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
    0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;

  @media (max-width: 350px) {
    padding: 1.8rem;
  }

  &__last {
    margin-bottom: 4.8rem;
  }

  &__column {
    flex-direction: column;
  }

  &__row {
    flex-direction: row;
    align-items: center;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .profile-info {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
