.aui-root .object-state-info-mapping {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  .btn-link.delete {
    color: var(--danger);
    font-size: smaller;
  }
}
