.aui-root {
  .grid-view {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem;

    @media (max-width: 768px) {
      margin: 2rem 0;
    }
  }
}
