.user-settings {
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin: 4rem 18rem 0;
  overflow: hidden;

  @media (max-width: 1840px) {
    margin: 4rem 8rem 0;
  }

  @media (max-width: 1440px) {
    margin: 4rem 2rem 0;
  }

  @media (max-width: 1024px) {
    margin: 3rem 0 0;
    flex-direction: column;
  }

  &__back {
    margin-bottom: 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    svg {
      height: 1.9rem;
      width: 1.9rem;
      transition: 0.15s ease-in-out all;

      path {
        fill: var(--primary);
      }
    }

    .text {
      margin: 0 0 0 1.5rem;
      color: var(--primary);
      transition: 0.15s ease-in-out color;
      white-space: nowrap;
    }

    &:hover {
      svg path {
        fill: var(--primary-contrast);
      }

      .text {
        color: var(--primary-contrast);
      }
    }
  }

  .menu {
    width: 19rem;

    @media (max-width: 1440px) {
      width: 23rem;
    }

    @media (max-width: 1024px) {
      width: 100%;
      display: block;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &__item {
      &__link {
        margin-bottom: 0.5rem;
        background: transparent;
        border: none;
        padding: 0.8rem 1.2rem;
        font-weight: 600;
        color: var(--input-text);
        font-size: 1.4rem;
        gap: 8px;
        border-radius: 8px;
        transition: 0.15s ease-in-out background, color;
        white-space: nowrap;

        &:hover {
          background: var(--tertiary-contrast);
          color: var(--primary-dark);
        }

        &.active {
          background: var(--primary);
          color: var(--white);
        }
      }
    }
  }

  &__details {
    flex: 1;
    padding: 0 21.5rem 18rem 7rem;
    margin-top: -2rem;
    overflow: scroll;
    max-height: 100vh;

    @media (max-width: 1840px) {
      padding: 0 16rem 18rem 7rem;
    }

    @media (max-width: 1440px) {
      padding: 0 0 8rem 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
