.organization-sidebar-info {
  background: var(--light-gray);
  border: 1px solid var(--light);
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.5rem;
  margin-bottom: 3rem;
  margin-top: -3rem;
  cursor: pointer;
  transition: 0.15s ease-in-out all;

  @media (max-width: 1440px) {
    margin: 1rem 1.5rem 2rem;
    width: calc(100% - 1.5rem);
  }

  @media (max-width: 1024px) {
    margin: 1rem 0;
  }

  &--collapsed {
    padding: 0.8rem;
    margin: -2.6rem auto 3.4rem;

    @media (max-width: 1440px) {
      margin: 1.2rem auto 2rem;
      width: initial;
    }

    .organization-sidebar-info__img {
      margin-right: 0;
    }
  }

  &:hover {
    border-color: var(--primary);
    background: var(--tertiary-contrast);

    .text {
      color: var(--primary);
    }

    svg path {
      fill: var(--primary);
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__img {
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid var(--light);
    border-radius: 6px;
    margin-right: 1.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3.2rem;

    &.image-blind {
      border-radius: 6px;
      width: 3.2rem;
      height: 3.2rem;
      font-size: 1.2rem;
      min-width: 3.2rem;
    }
  }

  .text {
    margin: 0;
    text-transform: capitalize;
    transition: 0.15s ease-in-out color;
  }

  svg path {
    transition: 0.15s ease-in-out fill;
  }
}
