.aui-root {
  .object-status {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;

    &--amount-wrapper {
      padding: 0.3rem 0.5rem 0.3rem 0.8rem;
    }

    &--Good {
      background: var(--success-50, #ecfdf3);
      color: var(--success-700, #027a48);
      border: 1px solid var(--success-700, #027a48);
    }

    &--Ok {
      background: var(--warning-50, #fffaeb);
      color: var(--warning-700, #b54708);
      border: 1px solid var(--warning-700, #b54708);
    }

    &--Bad {
      background: var(--error-50, #fef3f2);
      color: var(--error-700, #b42318);
      border: 1px solid var(--error-700, #b42318);
    }

    &--No {
      background: var(--light-contrast, #fef3f2);
      color: var(--default-text, #b42318);
      border: 1px solid var(--light, #b42318);
    }

    &__amount {
      color: var(--primary);
      background: var(--white);
      border-radius: 16px;
      padding: 0.2rem 0.8rem;
      margin: 0 0 0 1rem;
    }
  }
}
