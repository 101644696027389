/* Regular */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Semibold */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

:root {
  --default-font: "Inter", sans-serif;
}

html {
  font-size: 10px;
  overflow-y: initial !important;
  background-color: #fcfcfd;

  //@media (max-width: 1024px) {
  //  overflow: scroll !important;
  //}
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  max-width: 100vw;

  @media (max-width: 1024px) {
    margin-top: 0 !important;
  }
  &.aui-root {
    --default-font: "Inter", sans-serif;
  }
}

.aui-root {
  font-size: var(--default-font-size, 10px);

  .aui-popup-menu {
    background-color: white;
    border: 1px solid var(--gray-200, #dcdfea);
  }

  p,
  a,
  .btn {
    font-size: 1.6rem;
  }

  .btn:active {
    color: var(--white);
    background-color: var(--primary) !important;
  }

  .btn.light:active {
    background-color: #d9daf2 !important;
    border-color: var(--primary);
    color: var(--primary);
  }

  .btn.light-delete:active {
    background-color: #f044381a !important;
  }

  a {
    color: var(--primary);
    &:focus {
      &:after {
        display: none;
      }
    }
    &:hover {
      color: var(--primary-contrast);
    }
  }

  .aui-select {
    select {
      background: var(--white);
      line-height: 2.4rem;
      font-size: 1.6rem;
      font-weight: 400;
      height: 4.6rem;
      -webkit-appearance: none;
      color: var(--input-text);
      &:hover {
        border: 1px solid var(--primary);
      }
    }

    .aui-select-clear {
      display: none;
    }
  }

  .aui-switch:not(.aui-theme-mode-switch) {
    border: 1px solid var(--light);
    min-width: calc(var(--switch-size, 1rem) * 4);
    height: 2.6rem;
    background: var(--light);

    .aui-icon {
      transition-property: background-color, left, color;
      width: 1.45em;
      height: 1.45em;
      border-radius: 1em;
      background: var(--white);

      svg {
        display: none;
      }
    }

    &.active {
      background: var(--primary);
      border-color: var(--primary);
    }
  }

  section {
    .pagewrapper-wrapper {
      .score-rating {
        background: #eff1f5;
        border: 1px solid var(--dark-contrast);
        color: var(--dark-contrast);

        &--bad {
          border: 1px solid #d92d20;
          background: #fffbfa;

          p,
          span {
            color: #d92d20;
          }
        }

        &--medium {
          border: 1px solid #dc6803;
          background: #fffcf5;

          p,
          span {
            color: #dc6803;
          }
        }

        &--high {
          border: 1px solid #039855;
          background: #f6fef9;

          p,
          span {
            color: #039855;
          }
        }
      }
    }
  }
  input,
  textarea {
    color: var(--dark) !important;
  }

  .img-hover {
    &:hover {
      &:before {
        opacity: 0.3;
      }
    }

    &:before {
      content: "";
      position: absolute;
      background: #000;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      transition: 0.15s ease-in-out all;
      border-radius: 8px;
    }
  }
}

#root {
  margin: 0;
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.aui-textarea textarea {
  font-size: 1.4rem;
  resize: vertical;
  max-height: 160px;
}

.form,
.aui-form-control {
  label {
    margin-bottom: 0.6rem;
    font-size: 1.4rem;
  }

  input,
  button {
    margin-bottom: 2.4rem;
    min-width: 360px;

    @media (max-width: 1300px) {
      margin-bottom: 1.6rem;
    }

    @media (max-width: 1024px) {
      min-width: 340px;
    }

    @media (max-width: 360px) {
      min-width: 300px;
    }

    @media (max-width: 320px) {
      min-width: 280px;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    font-size: 1.4rem;

    &::placeholder {
      color: var(--input-text);
      font-size: 1.6rem;
    }

    &:hover {
      border-color: var(--primary);
    }
  }

  input[type="date"] {
    &:hover {
      border-color: var(--primary);
    }
    color: var(--dark) !important;
  }

  textarea {
    &::placeholder {
      color: var(--input-text);
      font-size: 1.6rem;
    }

    &:hover {
      border-color: var(--primary);
    }
  }

  input[type="date"] {
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field,
    &::-webkit-datetime-edit-text {
      color: var(--dark);
      opacity: 1;
      font-size: 1.6rem;
    }
  }

  .btn-form {
    margin-top: 3rem;
  }

  &__forgot-password {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 640px) {
      margin-top: -0.8rem;
      margin-bottom: -1rem;
    }

    .aui-check-box {
      margin-bottom: 0;

      label {
        margin-bottom: 0;
      }
    }

    p {
      margin: 0;
    }
  }

  .aui-form-control {
    position: relative;

    .info {
      margin: 0;
      position: absolute;
      bottom: -2.5rem;
      font-size: 1.2rem;
      color: var(--danger);
    }

    :invalid:focus {
      border-color: var(--danger);
    }

    &:has(:invalid:focus) label::after {
      content: url("../src/assets/alert-circle.svg");
      display: inline-flex;
      vertical-align: middle;
      height: 1.7rem;
      margin-left: 0.2rem;
    }

    // Todo remove and replace with code above

    &.input-error {
      position: relative;

      &.aui-select {
        label {
          &::before {
            content: url("../src/assets/alert-circle.svg");
            position: absolute;
            top: 56%;
            right: 3.8rem;
            z-index: 10;
          }
        }
      }

      label {
        &::before {
          content: url("../src/assets/alert-circle.svg");
          position: absolute;
          top: 56%;
          right: 1.5rem;
          z-index: 10;
        }
      }

      select {
        border-color: var(--danger) !important;
      }

      input {
        border-color: var(--danger);
      }
    }
    //----------------
  }
}

.aui-root {
  h4 {
    color: var(--defaultTextColor);
  }

  .btn {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--white);
    border-color: var(--primary);
    transition: all 200ms ease-out;

    &:hover {
      background: var(--btn-medium-hover-background);
      color: var(--white);
    }

    &:disabled {
      display: flex;
      justify-content: center;
      opacity: 1;
      background: #d7d8f7;
      border-color: #d7d8f7;
      font-weight: 600;
      color: var(--white);
    }

    &:focus {
      &:after {
        display: none;
      }
    }

    &.light {
      background-color: var(--white);
      color: var(--default-text-color);

      &:hover,
      &.active {
        color: var(--primary);
        border-color: var(--primary);
      }
      &:hover {
        background-color: var(--tertiary-contrast);
      }

      &.active {
        background-color: rgba(var(--primary-lighter-rgb), 0.4);
      }
    }

    &.light-delete {
      background-color: var(--white);
      color: var(--danger);
      border-color: var(--danger);

      &:hover,
      &.active {
        background-color: #f044381a;
      }

      &:hover {
        background-color: #f044381a;
      }
    }

    &.success {
      background-color: #68ce20;
      border-color: #68ce20;
      color: var(--white);

      &:hover {
        background-color: #7dde37;
        border-color: #7dde37;
      }
    }

    &:disabled {
      opacity: 0.6;
    }

    &-delete {
      background: var(--danger);
      border-color: var(--danger);

      &:hover {
        background: var(--danger-darker);
        color: var(--white);
      }
    }

    &-cancel {
      display: flex;
      justify-content: center;
      font-weight: 600;
      background: var(--white);
      color: var(--defaultTextColor);
      border-color: #b9c0d4;

      &:hover {
        background: #b9c0d4;
      }
    }

    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  .btn-link {
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: var(--primary-contrast);
    }
  }

  .input-error {
    input {
      border-color: var(--danger);
    }
    select:invalid:not(:placeholder-shown) {
      border-color: var(--danger);
    }
  }

  .aui-overlay {
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
  }

  .aui-radio .checkmark {
    min-width: initial;
  }

  .aui-check-box label {
    position: relative;
    input {
      width: 100%;
      height: 1px;
      bottom: 0;
      margin: 0;
    }
  }

  .aui-check-box,
  .aui-radio {
    &:not(.disabled) {
      &:focus-within {
        &::after {
          content: none;
        }
      }
    }

    label {
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--label-text);
    }

    label:not(.disabled) {
      &:hover,
      &:focus-within {
        .checkmark {
          border-color: var(--primary);

          &.checked {
            background-color: var(--primary);
            color: var(--white);
            border-color: var(--primary);
          }
        }
      }
    }

    .checkmark.checked {
      background-color: var(--primary);
      color: var(--white);
      border-color: var(--primary);
    }

    .checkmark {
      width: 1.6rem;
      height: 1.6rem;

      &.checked svg {
        font-size: 1.4rem;
      }
    }
  }

  .optional {
    display: none;
  }

  // styling for top warning bar if in dev instance
  .dev-instance-warning {
    background: var(--warning);
    color: var(--dark);
    padding: 0.5rem;
    height: 2.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    position: fixed;
    z-index: 1000;
    width: 100%;

    .aui-icon {
      vertical-align: -0.5rem;
      height: auto;
    }

    p {
      font-size: 1.2rem;
    }
  }
}
