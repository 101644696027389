.aui-root {
  .aside-item-step {
    min-width: max-content;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    height: 4rem;
    gap: 2.4rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
    &:hover {
      background-color: var(--link-text);
      border-radius: 0.6rem;
      width: 100%;

      .aside-item-step__circle {
        background-color: var(--tertiary-contrast);
      }
    }
    &--active {
      background-color: var(--link-text);
      border-radius: 0.6rem;
      width: 100%;

      .aside-item-step__circle {
        color: var(--white);
        background-color: var(--primary);
        border: none;
      }
      &:hover {
        .aside-item-step__circle {
          background-color: var(--primary);
        }
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding: 0;
    }

    &__circle {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.8rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary);
      background-color: transparent;
      border: 2px solid var(--primary);
    }

    div {
      color: var(--input-text-color);
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
      display: flex;
      flex-direction: column;

      span {
        text-align: left;
      }
    }

    &__subtext {
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 400;

      &--required {
        color: var(--danger);
      }
    }
  }
}
