.aui-root {
  .tooltip-input {
    position: relative;

    &__icon {
      position: absolute;
      top: 4rem;
      right: 0.3rem;

      height: 1.8rem;

      svg {
        height: 1.8rem;

        path {
          fill: #344054b2;
        }
      }
    }

    &__message {
      position: absolute;
      right: -0.5rem;
      top: -1.5rem;
      background: var(--dark-contrast);
      color: var(--white);
      padding: 0.2rem 1rem;
      border-radius: 6px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 500;
      max-width: 50rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.15s ease-in-out opacity;
      cursor: pointer;

      &--show {
        visibility: visible;
        opacity: 1;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: -0.8rem;
        right: 1.5rem;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 0.8rem solid transparent;
        border-right: 0.8rem solid transparent;
        border-top: 0.8rem solid var(--dark-contrast);
      }
    }
  }
}
