.aui-root {
  .sidebar-link {
    font-size: 1.4rem;
    outline: none;
    border: none;
    text-decoration: none;
    padding: 2rem 2rem 2rem 1.2rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #7d89b0;
    height: 4.4rem;
    min-width: 4rem;
    margin-bottom: 1rem;
    position: relative;
    transition: 0.15s ease-in-out all;
    margin-left: 0.8rem;

    @media (max-width: 1440px) {
      margin-bottom: 0.7rem;
    }

    @media (max-width: 1024px) {
      padding: 0;
      margin-bottom: 0.8rem;
      margin-left: 0;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2rem;
    }

    span {
      margin-left: 1.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    path {
      transition: 0.15s ease-in-out fill;
      fill: #7d89b0;
    }

    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      top: 0;
      right: -2rem;
      bottom: 0;
      width: 4px;
      height: 100%;
      background: var(--primary);
      z-index: 10;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      transition: 0.15s ease-in-out opacity;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &--active {
      background: var(--tertiary-contrast);
      color: var(--primary);
      border-radius: 16px;

      @media (max-width: 1024px) {
        background: transparent;
      }

      &:before {
        opacity: 1;
      }

      svg,
      path {
        fill: var(--primary);
      }
    }

    &:hover:not(&--active) {
      color: var(--primary);

      path {
        fill: var(--primary);
      }
    }

    &--collapsed {
      border-radius: 12px;
      padding: 1.2rem;
      align-self: center;
      margin-left: 0;

      svg {
        margin-right: 0;
      }

      &:before {
        display: none;
      }
    }
  }
}
