.locations-title-button {
  background-color: var(--white);
  height: 4rem;
  display: flex;
  padding: 1rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--default-text-color);
  margin: 0 0.6rem;

  span {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.locations-title-button:last-of-type {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  margin: 0 0 0 0.6rem;
}

.aui-root hr {
  background: var(--light);
  margin: 0;
}
