.aui-root .notification-dialog {
  padding: 2rem;

  .dialog-header {
    gap: 1rem;

    .aui-icon {
      font-size: large;
      width: initial;
      height: initial;
      padding: 12px;
      position: relative;
      z-index: 2;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: currentColor;
        border-radius: 100px;
        opacity: 0.1;
        z-index: -1;
      }

      &::before {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &::after {
        width: 70%;
        height: 70%;
        top: 15%;
        left: 15%;
      }
    }
  }

  h4 {
    margin: 0;
    color: var(--defaultTextColor);
    font-size: 1.8rem;
  }

  p {
    max-width: 500px;
    font-size: 1.4rem;
  }

  .buttons {
    .btn {
      flex: 1 1 0;
    }
  }
}
