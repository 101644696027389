.aui-root {
  .feature-modal {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-button {
      display: none;
    }
    .feature-modal-container {
      overflow-y: auto;
      min-height: fit-content;

      @media (max-width: 850px) {
        overflow-y: hidden;
      }
    }

    p {
      margin: 5px;
    }
    hr {
      padding: 0.1px;
    }
  }
}

// ----  Payment Modal ----

.aui-root {
  .change-plan {
    .modal-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: var(--white);
      border-bottom: 1px solid var(--light);
      padding-top: 2rem;
    }
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    &__occupied {
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      border: 1px solid var(--warning);
      padding: 1.5rem;
      background-color: rgba(247, 144, 9, 0.1);
      align-items: center;
      .btn {
        height: 42px;
        min-width: 192px;
        margin-left: 10rem;
      }
      &__text {
        display: flex;
        flex-direction: column;
        text-align: left;
        .occupied__title {
          font-size: 18px;
          font-weight: 600;
          color: var(--warning);
        }
      }
    }
    &__payment-settings {
      background-color: #fcfcfd;
      height: fit-content;
      border-radius: 8px;
      border: 1px solid var(--light);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__annual-billing,
    &__seats {
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      text-align: left;
      &__text {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .annual-billing,
        .seats {
          &__title {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
      &__switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        .annual-billing {
          &__switch {
            &__text {
              font-size: 16px;
              font-weight: 500;
              color: var(--primary900, #271757);
              margin-left: 1rem;
            }
          }
        }
      }
    }
    &__seats {
      &__counter {
        display: flex;
        align-items: center;
        margin-right: 5px;
        .elements-counter {
          width: 17rem;
        }
      }
    }
    &__overview {
      border-radius: 8px;
      border: 1px solid var(--primary);
      display: flex;
      flex-direction: column;
    }
    &__summary {
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__text {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        .summary__title {
          font-size: 18px;
          font-weight: 600;
        }
      }
      &__price {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .text {
          font-size: 18px;
          font-weight: 600;
        }
        .discount {
          color: #12b76a;
        }
      }
    }
    &__billing-info {
      padding: 0 1.5rem 1.5rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .billing-info__title {
        font-size: 18px;
        font-weight: 600;
      }
    }
    &__billing-info-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 1.5rem;
      .billing-info__title {
        font-size: 18px;
        font-weight: 600;
      }

      .input-error {
        & input[type="text"]:focus {
          border-color: var(--danger);
        }

        label {
          &::before {
            opacity: 1;
          }
        }
      }

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        text-align: left;
        width: 100%;

        label {
          max-width: 45%;
          position: relative;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 400;

          &::before {
            content: url("../../../src/assets/alert-circle.svg");
            position: absolute;
            right: -2rem;
            z-index: 10;
            opacity: 0;
            top: 50%;
            transform: translateY(-40%);
          }
        }

        input {
          width: 50%;
          font-size: 1.6rem;
          font-weight: 400;
        }
      }
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &__title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;

          @media (max-width: 640px) {
            width: 50%;
          }
        }
        &__radio {
          display: flex;
          flex-direction: row;
          width: 35%;
          justify-content: space-evenly;
          align-items: center;
          @media (max-width: 640px) {
            flex-direction: column;
            margin-top: 3rem;
            width: 50%;
          }
          .aui-radio {
            label {
              font-size: 1.6rem;
            }
            .checkmark {
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
    }

    &__next-payment {
      padding: 0 1.5rem 1.5rem 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .next-payment {
        &__text {
          font-size: 18px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &__title,
        &__date {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    &__next-bill {
      padding: 2rem 1.5rem 2rem 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--primary);
      background-color: rgba(98, 57, 217, 0.1);
      .next-bill__logo-text {
        display: flex;
        align-items: center;
        span.aui-icon.wallet-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 2rem;
          color: var(--primary);
          svg {
            height: 2rem;
            width: 2rem;
          }
        }
      }

      p {
        font-size: 18px;
        font-weight: 600;
        color: var(--primary);
        margin-left: 1rem;
      }
    }
    &__charging-info {
      text-align: left;
    }
    &__buttons {
      position: sticky;
      bottom: 0;
      padding: 2rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--light);
      background-color: white;
      .btn {
        width: 48%;
      }
    }
  }
}

// ----  AddOrgModal ----

.aui-root {
  .add-org-modal {
    background: var(--white);
    width: 66rem;
    border-radius: 12px;
    padding: 2rem;
    position: relative;

    @media (max-width: 640px) {
      width: 100%;
      margin: 0 2rem;
    }

    .heading {
      h3 {
        margin: 0;
      }
    }

    &__close {
      position: absolute;
      right: 2rem;
      cursor: pointer;

      svg {
        vertical-align: -0.6rem;
      }
    }

    .dropzone-label {
      margin-bottom: 0.6rem;
      font-size: 1.4rem;
      font-weight: var(--label-font-weight, var(--default-font-weight, 400));
      color: var(--label-text, var(--default-text, #000));
      transition: color ease-out 200ms;
    }

    &__buttons {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 3.2rem;

      @media (max-width: 640px) {
        flex-direction: column;
      }

      button {
        width: 90%;

        @media (max-width: 640px) {
          width: 100%;
        }
      }

      &--cancel {
        margin-right: 1.2rem;
      }
    }

    .form {
      .aui-form-control,
      input {
        margin-bottom: 0.5rem;
      }
    }
  }
}
