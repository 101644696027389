.add-new-user-modal {
  background: #fafbff;
  border: 1px solid var(--light);
  padding: 2.4rem;
  border-radius: 16px;
  margin-bottom: 2.4rem;
  cursor: pointer;
  transition: 0.15s ease-in-out all;
  position: absolute;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__icon {
    svg {
      height: 2.5rem;
      width: 2.5rem;

      path {
        fill: var(--primary);
      }
    }
  }

  h4 {
    color: var(--dark);
    font-weight: 700;
    margin-top: 5rem;
    margin-bottom: 1rem;
    position: relative;
    transition: 0.15s ease-in-out top;
    top: 0;

    @media (max-width: 1200px) {
      margin-top: 3rem;
    }
  }
  h6 {
    color: var(--input-text);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 2.4rem;
    position: relative;
    transition: 0.15s ease-in-out all;
    top: 0;
  }

  &:hover {
    border-color: var(--primary);
    background: var(--input-border-color-single);

    h4,
    h6 {
      top: -1rem;
    }
  }
}

.aui-root {
  .feature-modal {
    .add-user-modal-container {
      min-height: 32rem;
      width: 60rem;
      .toplabel,
      .underlabel {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
        color: var(--gray-600, #4a5578);
        text-align: left;
        margin-top: 0;
      }
      .toplabel {
        margin-bottom: -1rem;
      }
      .add-user-error {
        opacity: 1;
        text-align: left;
        margin-top: -1rem;
      }
    }
  }
}

.createable-select {
  .react-select {
    &__control {
      border-radius: var(--btn-radius);
    }
    &__control--is-focused,
    &__input-container,
    &__placeholder {
      cursor: text;
    }
    &__indicators {
      cursor: pointer;
    }
    &__value-container {
      .react-select {
        &__placeholder,
        &__input-container,
        &__multi-value__label,
        &__input {
          color: var(--dark);
          font-weight: 500;
          font-size: 14px;
          text-align: left;
        }
        &__placeholder {
          opacity: 0.6;
        }
        &__multi-value {
          background-color: var(--white);
          border: var(--light) 1px solid;
          cursor: default;
          font-size: 16px;
          font-weight: 400;
          border-radius: var(--btn-radius);
          &__remove {
            cursor: pointer;
          }
        }
      }
    }
  }
}
