.aui-root {
  .custom-input-select {
    display: block;
    text-align: left;

    label {
      text-align: left;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .select-container {
      position: relative;

      .aui-select-clear {
        display: none;
      }

      select {
        padding-right: 3rem;
        option {
          color: var(--input-text);
        }
      }

      .aui-grow {
        color: var(--primary);
      }

      .placeholder-shown.aui-grow {
        color: var(--input-text);
      }

      .aui-icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
